import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import lucene from 'lucene';
import {
  AudienceBulkActionFilters,
  BulkSelection,
  OptionType,
} from 'hooks/common';
import { bossanovaDomain, request } from './api-shared';
import { PaginationData } from './common';

// Clean up ideas if you are reading this:
//  - The headers are duplicated many times, because
//    the helper function that isn't being used consistently
//  - The errors thrown from 200 responses could be documented
//  - isNumeric is defined here, and in another file. They
//    can both import from a shared util
//  - This file is ginormous
//    - Maybe refactor into a couple files? ex `api-audiences-counts` etc
//      - This file deals with audiences plural and singular, and the names
//        require special attention to make sure you are reading the
//        code you think you are.
//    - Move the "important" functions up at the top
//  - There is at least 1 function that throws errors because that's the
//    react-query way, but it is also being imported and used in components
//    directly, without error handling. This can be documented or something.
//  - Consistent naming (unarchive and unArchive)
//  - Group the types and data consts

type AudienceCommonData = {
  id?: string;
  description?: string;
  expression?: string;
  name: string;
  programId: number;
  query?: string;
  state: string;
  title: string;
  type: string;
};

export type AudienceReadData = {
  tags: Array<string>;
  totalUsers?: number;
  numericId: string;
} & AudienceCommonData;

export type AudienceSourceData = {
  id: string;
  type: string;
  attributes: AudienceReadData;
};

export type AudiencesCollectionData = {
  data: Array<AudienceSourceData>;
  meta: PaginationData;
};

export type AudienceWriteData = {
  tags: string;
} & AudienceCommonData;

type CriterionData = {
  id: string;
  type: string;
  attributes: {
    key: string;
  };
};

export type CriterionCollectionData = {
  data: Array<CriterionData>;
};

export type UserExportData = {
  id: number;
  status: string;
  downloadUrl: string;
  identifier?: string;
};

export type CampaignAudienceQuery = {
  criterion_v2: {
    or: Array<{
      key: string;
      operator: string;
      value: string;
    }>;
  };
};

// we want to exclude expression and query because audiences with user ids in their payload can be huge.
const DEFAULT_FIELDS =
  'program_id,name,type,title,description,total_users,numeric_id,creator,creator_id,created_at,updated_at,state,static,tags';

export const fetchValueSuggestions = async (
  programId: number,
  criterion: string,
  term: string,
  scope: string,
  regex = false
): Promise<OptionType[] | undefined> => {
  const safeTerm = () => {
    const escaped = regex ? term : lucene.term.escape(term);
    return escaped.replace('\\*', '*').replace('\\ ', ' ');
  };
  const query = qs.stringify({
    criterion,
    match: safeTerm(),
    scope,
    visible: 'visible',
    max: 101,
    ignore_escape: true,
    regex,
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/criteria/suggest?${query}`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((output) =>
      camelcaseKeys(output.values).map(
        ({
          count,
          label,
          value,
        }: {
          count?: string;
          label?: string;
          value: string;
        }) => ({
          label:
            (label === undefined ? value : label) +
            (count ? ` (${count})` : ''),
          value,
        })
      )
    );
  }

  throw new Error(`Error fetching audiences: ${response.status}`);
};

export type AudienceTag = {
  id: string;
  type: string;
  attributes: {
    name: string;
    'group-count': number;
  };
};

export type AudienceTagsResponse = {
  data: AudienceTag[];
};

export const fetchAudienceTags = async (
  programId: number
): Promise<AudienceTagsResponse | undefined> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/tags`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json();
  }

  throw new Error(`Error fetching audience tags: ${response.status}`);
};

export const fetchFavoriteAudiences = async (
  programId: number
): Promise<number[] | undefined> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/favorites`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json();
  }

  throw new Error(`Error fetching favorite audiences: ${response.status}`);
};

// TODO: tags, statuses, and types are no longer used as plurals
//       Rename to singular names, and update the calls to them
export type FetchAudiencesProps = {
  programId: number;
  page?: number;
  pageSize?: number;
  visibility?: string;
  fields?: string;
  order?: string;
  search?: string;
  tags?: string | string[];
  statuses?: string | string[];
  types?: string | string[];
  favorites?: boolean;
  name?: string | string[];
};

export const fetchAudiences = async (
  props: FetchAudiencesProps
): Promise<AudiencesCollectionData> => {
  const {
    programId,
    page = 1,
    pageSize,
    visibility,
    fields = DEFAULT_FIELDS,
    order = 'title',
    search,
    tags,
    statuses,
    types,
    favorites,
    name,
  } = props;

  const query = qs.stringify(
    snakecaseKeys({
      page,
      descriptive: true,
      perPage: pageSize,
      visibility,
      fields,
      order,
      q: search,
      tagsList: tags,
      statuses,
      types,
      favorites,
      name,
      state: name ? 'any' : undefined,
    }),
    { arrayFormat: 'brackets' }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups?${query}`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((output) => {
      const result = camelcaseKeys(output, { deep: true });
      // This query does not follow the same naming convention for pagination meta as many other
      // queries we have.  This normalizes to be able to use infinite query utilities.
      if (result.meta) {
        result.meta.totalRecords = result.meta.totalObjects;
        result.meta.currentPage = result.meta.pageNumber;
      }
      return result;
    });
  }
  throw new Error(`Error fetching audiences: ${response.status}`);
};

export type FetchUsersCountResponse = {
  totalObjects: number;
};

const emptyUsersCountResponse: FetchUsersCountResponse = { totalObjects: 0 };

export const fetchAudienceByParams = async (
  programId: number,
  params: {
    type?: string;
    name?: string;
    exact_title?: string;
  }
): Promise<AudienceReadData> => {
  const { name, type, exact_title } = params;
  const query = qs.stringify({
    name,
    type,
    exact_title,
    fields: DEFAULT_FIELDS,
    state: 'any',
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups?${query}`;
  const response = await request(url);
  if (response.status === 200) {
    const output = await response.json();
    if (output.data.length > 0) {
      return camelcaseKeys(output.data[0].attributes);
    }
    throw new Error(`Error fetching audience by params: no data was returned`);
  }
  throw new Error(`Error fetching audience by params: ${response.status}`);
};

const fetchAudienceById = async (
  programId: number,
  id: string
): Promise<AudienceReadData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/${id}`;
  const response = await request(url);
  if (response.status === 200) {
    const output = await response.json();
    if (output.data) {
      return camelcaseKeys(output.data.attributes);
    }
    throw new Error(`Error fetching audience by id: no data was returned`);
  }
  throw new Error(`Error fetching audience by id: ${response.status}`);
};

function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}

export const fetchAudience = async (
  programId: number,
  audienceId: string
): Promise<AudienceReadData> => {
  if (isNumeric(audienceId)) return fetchAudienceById(programId, audienceId);
  const parts = audienceId.split('.');
  const params = { type: parts[0], name: parts[1] };
  return fetchAudienceByParams(programId, params);
};

export const fetchUsersCount = async (
  q: string,
  programId: number,
  groupId?: number,
  contentId?: number
): Promise<FetchUsersCountResponse> => {
  if (q === '') return Promise.resolve(emptyUsersCountResponse);

  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/query/summary`;
  const data = {
    q,
    group_id: groupId,
    content_id: contentId,
    visibility: 'visible',
  };
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => ({
      totalObjects: output.data.attributes['total-users'],
    }));
  }
  throw new Error(`Error fetching user count: ${response.status}`);
};

function defaultJsonHeaders() {
  return {
    'Content-Type': 'application/json',
    'x-requested-with': 'XMLHttpRequest',
  };
}

async function createOrUpdateAudience(
  audience: AudienceWriteData,
  opts: { create?: boolean }
): Promise<AudienceReadData> {
  const { programId, ...data } = audience;
  const params = opts.create ? '/?creating=true' : '';
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups${params}`;

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(data)),
    headers: defaultJsonHeaders(),
  });

  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }

  const { errors } = await response.json();

  if (errors) {
    throw new Error(errors.join(', '));
  }

  throw new Error(`Error creating audience: ${response.status}`);
}

export async function createAudience(
  audience: AudienceWriteData
): Promise<AudienceReadData> {
  return createOrUpdateAudience(audience, { create: true });
}

export async function updateAudience(
  audience: AudienceWriteData
): Promise<AudienceReadData> {
  return createOrUpdateAudience(audience, { create: false });
}

export const archiveAudience = async (
  audience: AudienceWriteData
): Promise<AudienceReadData> => {
  const { programId, ...data } = audience;
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/${audience.name}/archive`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error archiving audience: ${response.status}`);
};

export const addAudienceToFavorites = async (
  programId: number,
  audienceId: number
): Promise<string> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/${audienceId}/favorites`;
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error adding audience to favorites: ${response.status}`);
};

export const exportAudienceUsers = async (
  programId: number,
  type: string,
  name: string,
  title: string,
  query: string,
  exportType: string
): Promise<UserExportData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/user_exports`;
  const data = {
    group_identifier: `${type}.${name || 'unsaved'}`,
    group_title: `${type}.${title || 'unsaved'}`,
    order: { field: 'name', direction: 'asc' },
    filter: exportType === 'query' ? { group_query: query } : {},
    type: exportType,
  };

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 201) {
    return response.json().then((output) => camelcaseKeys(output.data));
  }
  throw new Error(`Error exporting audience: ${response.status}`);
};

export const fetchUserExport = async (
  programId: number,
  id: number
): Promise<UserExportData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/user_exports/${id}`;
  const response = await request(url);
  if (response.status === 200) {
    const output = await response.json();
    if (output.data) {
      return camelcaseKeys(output.data);
    }
    throw new Error(`Error fetching user_export: no data was returned`);
  }
  throw new Error(`Error fetching user_export: ${response.status}`);
};

export const removeAudienceFromFavorites = async (
  programId: number,
  audienceId: number
): Promise<string> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/${audienceId}/favorites`;
  const response = await request(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error archiving audience: ${response.status}`);
};

export const unarchiveAudience = async (
  audience: AudienceWriteData
): Promise<AudienceReadData> => {
  const { programId, ...data } = audience;
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/${audience.name}/unarchive`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error unarchiving audience: ${response.status}`);
};

export const createSnapshot = async (
  audience: AudienceWriteData
): Promise<string> => {
  const { programId, ...data } = audience;
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/${audience.name}/snapshots`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output).groupId);
  }
  throw new Error(`Error creating a snapshot: ${response.status}`);
};

export const fetchAudienceCriteria = async (
  programId: number
): Promise<CriterionCollectionData | undefined> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/criteria?exclude_unpopulated_profile_fields=true`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json();
  }

  throw new Error(`Error fetching audience criteria: ${response.status}`);
};

export const archiveAudiences = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: AudienceBulkActionFilters
): Promise<AudienceReadData> => {
  const { search, tags, statuses, types, favorites } = filterConfig;
  const query = qs.stringify(
    snakecaseKeys({
      descriptive: 'true',
      q: search,
      tagsList: tags,
      statuses,
      types,
      favorites,
    }),
    { arrayFormat: 'brackets' }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/archive/bulk?${query}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error archiving audience: ${response.status}`);
};

export const unArchiveAudiences = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: AudienceBulkActionFilters
): Promise<AudienceReadData> => {
  const { search, tags, statuses, types, favorites } = filterConfig;
  const query = qs.stringify(
    snakecaseKeys({
      descriptive: 'true',
      q: search,
      tagsList: tags,
      statuses,
      types,
      favorites,
    }),
    { arrayFormat: 'brackets' }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/unarchive/bulk?${query}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error un-archiving audience: ${response.status}`);
};

export const fetchCampaignUsersCount = async (
  programId: number,
  campaignAudience: CampaignAudienceQuery
): Promise<{ advocateCount: number }> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/audiences/count`;
  const body = {
    allow_publisher: true, // temporary flag to allow publishers to use this endpoint
    ...campaignAudience,
  };
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error fetching campaign users count: ${response.status}`);
};

interface Response {
  outputs: [string];
  task_ids: [string];
}

export const fetchSuggestedQuery = async (
  programId: number,
  criteria: Array<OptionType>,
  description: string,
  initialQuery: string
): Promise<Response> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/suggest_query`;
  const body = {
    description,
    criteria,
    query: initialQuery,
  };
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) return response.json();
  throw new Error(`Error fetching a suggested query: ${response.status}`);
};
