import { Section } from 'shared/SectionBlocks';
import { Field, Fieldset } from 'shared/Fieldset';
import React, { useCallback } from 'react';
import cx from 'classnames';
import styles from 'components/publisher/settings/SettingsEditor/settings-editor.module.css';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { Settings } from 'models/publisher/settings';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Checkbox } from 'shared/Checkbox';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { RestrictedFields } from 'hooks/publisher/settings/restrictedFields';
import { useNotificationCenterLabel } from 'hooks/notification-center';
import { EmailSenderAliasField } from './sections/Administration/EmailSenderAliasField';

export type DeliveryChannelOption = {
  name: keyof Settings['deliveryChannels'];
  label: string;
  permitted: boolean;
};

const filterActive = (
  options: DeliveryChannelOption[],
  isEmailOnly: boolean
): DeliveryChannelOption[] => {
  if (isEmailOnly) {
    return options.filter((option) => option.name === 'email');
  }
  return options;
};

const useChannelSettings = () => {
  const { contentPermissions, fields, settings, update } = useSettings();
  const { deliveryChannels, emailSenderAlias } = settings;
  const { restrictedFields } = contentPermissions;

  const emailOnly = !!useFeatureFlag('emailOnly');

  const onChannelChange = useCallback(
    ({ name, value }) => {
      update({
        deliveryChannels: {
          ...settings.deliveryChannels,
          [name]: value,
        },
      });
    },
    [update, settings]
  );

  const onEmailSenderAliasChange = useCallback(
    (newEmailSenderAlias) => {
      update({ emailSenderAlias: newEmailSenderAlias });
    },
    [update]
  );
  const notificationCenterLabel = useNotificationCenterLabel();

  const channels: Array<DeliveryChannelOption> = filterActive(
    [
      {
        name: 'email',
        label: 'Email',
        permitted: !restrictedFields.includes(RestrictedFields.SEND_EMAIL),
      },
      {
        name: 'assistant',
        label: notificationCenterLabel,
        permitted: !restrictedFields.includes(
          RestrictedFields.SEND_TO_ASSISTANT
        ),
      },
      {
        name: 'push',
        label: 'Push notification',
        permitted: !restrictedFields.includes(RestrictedFields.SEND_PUSH),
      },
    ],
    emailOnly
  );

  const { id: programId } = useProgram();
  const orchestrateDeliveryForYou = !!useFeatureFlagsQuery(
    programId,
    'Orchestrate.ForYou.Skip'
  ).data?.value;
  const turnOffAndHideForYou = !!useFeatureFlagsQuery(
    programId,
    'Orchestrate.ForYou.Skip.DefaultOn'
  ).data?.value;

  return {
    channels,
    onChannelChange,
    settings,
    emailOnly,
    onEmailSenderAliasChange,
    emailSenderAlias,
    deliveryChannels,
    orchestrateDeliveryForYou,
    fields,
    restrictedFields,
    turnOffAndHideForYou,
  };
};

export const ChannelSettings: React.FC<{ disabled: boolean }> = ({
  disabled,
}) => {
  const {
    channels,
    onChannelChange,
    settings,
    onEmailSenderAliasChange,
    emailSenderAlias,
    deliveryChannels,
    orchestrateDeliveryForYou,
    fields: {
      includeInForYou: { set: setIncludeInForYou },
    },
    restrictedFields,
    turnOffAndHideForYou,
  } = useChannelSettings();

  return (
    <Section
      title="Channels"
      className={cx({
        [styles.disabledSetting]: disabled,
      })}
    >
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field
            className={styles.fieldLabel}
            label="Audience will receive campaign on one or more channels"
          >
            <div className={cx(styles.channels, styles.channelsWrapper)}>
              <div className={cx(styles.channels, styles.channelsCheckboxes)}>
                {channels.map(({ name, label, permitted }) => (
                  <span
                    className={cx({ [styles.disableCheckbox]: !permitted })}
                    data-test={`channel-settings-${name}`}
                    key={`channel-settings-${name}`}
                  >
                    <label className={styles.channelsLabel}>
                      <Checkbox
                        name={name}
                        onChange={(v) => onChannelChange({ name, value: v })}
                        checked={settings.deliveryChannels[name]}
                        className={cx({
                          [styles.disabledSetting]: disabled || !permitted,
                        })}
                        disabled={disabled || !permitted}
                      />
                      <div>{label}</div>
                    </label>
                  </span>
                ))}
              </div>
            </div>
          </Field>
        </div>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <EmailSenderAliasField
            onChange={onEmailSenderAliasChange}
            value={emailSenderAlias}
            disabled={
              disabled ||
              !deliveryChannels.email ||
              restrictedFields.includes(RestrictedFields.EMAIL_SENDER_ALIAS)
            }
          />
        </div>

        {!turnOffAndHideForYou && orchestrateDeliveryForYou && (
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field className={styles.fieldLabel} label="For You">
              <div className={cx(styles.channels, styles.channelsCheckboxes)}>
                <label className={styles.channelsLabel} key="For You">
                  <Checkbox
                    name="For You"
                    onChange={(value) => setIncludeInForYou(!!value)}
                    checked={settings.includeInForYou}
                    disabled={disabled}
                  />
                  <div>
                    Show campaign in the <strong>For You</strong> section for
                    selected audience
                  </div>
                </label>
                <div className={styles.help}>
                  Campaign will appear in <strong>For You</strong> if user does
                  not have access to selected topics or no topics have been
                  selected.
                </div>
              </div>
            </Field>
          </div>
        )}
      </Fieldset>
    </Section>
  );
};
