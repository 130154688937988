import { useState } from 'react';
import { MAX_NOTIFICATION_LENGTH } from '../../../models/notification';
import { useProgram } from '../../../contexts/program';
import { useFeatureFlagsQuery } from '../../../hooks/feature-flags';
import { useSendTestEmail } from '../../../hooks/publisher';
import { useSendTestPush } from '../../../hooks/useSendTestPush';
import {
  CheckedChannelsState,
  useChannelSelectionState,
} from '../../channel-selection/hooks';
import { User } from '../../../models/user';
import { useNotifications } from '../../../App/Program/Editors/Publisher/Deliver/Notifications/useNotifications';
import { useFeatureFlag } from '../../../hooks/useFeatureFlags';
import { usePublisher } from '../../../contexts/publisher';

type ChannelName = keyof CheckedChannelsState;

type GenericChannel = {
  name: ChannelName;
  valid: boolean;
  sendRequest: () => Promise<void>;
};

type EmailChannel = GenericChannel & {
  subject: string;
  previewText: string;
  setSubject: (subject: string) => void;
  setPreviewText: (text: string) => void;
};

type PushChannel = GenericChannel & {
  message: string;
  setMessage: (message: string) => void;
};

type NotificationCenterChannel = GenericChannel & {
  title: string;
  markAsImportant: boolean;
  setTitle: (title: string) => void;
  setMarkAsImportant: (markAsImportant: boolean) => void;
};

const useEmailChannel = (userIds: number[]) => {
  const { firstNotification: notification } = useNotifications();
  const { post } = usePublisher();
  const { id: programId } = useProgram();
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(programId, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );

  const [subject, setSubject] = useState<string>(notification.text);
  const [previewText, setPreviewText] = useState<string>(
    notification.previewText || ''
  );

  const { sendEmailRequest } = useSendTestEmail(
    subject,
    previewText,
    post,
    userIds,
    preferOutlook365
  );

  const valid =
    subject.length !== 0 &&
    subject.length <= MAX_NOTIFICATION_LENGTH &&
    previewText.length <= MAX_NOTIFICATION_LENGTH;

  return {
    valid,
    sendRequest: sendEmailRequest,
    subject,
    previewText,
    setSubject,
    setPreviewText,
  };
};

const useNotificationCenterChannel = () => {
  const { firstNotification: notification } = useNotifications();
  const [title, setTitle] = useState<string>(notification.text);
  const [markAsImportant, setMarkAsImportant] = useState<boolean>(false);

  const valid = title.length !== 0 && title.length <= MAX_NOTIFICATION_LENGTH;

  return {
    valid,
    // TODO: https://firstup-io.atlassian.net/browse/FE-8935
    sendRequest: async () => {},
    title,
    markAsImportant,
    setMarkAsImportant,
    setTitle,
  };
};

const usePushChannel = (userIds: number[]) => {
  const { firstNotification: notification } = useNotifications();
  const { post } = usePublisher();
  const [message, setMessage] = useState<string>(notification.pushText || '');

  const valid =
    message.length !== 0 && message.length <= MAX_NOTIFICATION_LENGTH;

  const { sendPushRequest } = useSendTestPush(
    message,
    '', // push messages do not have a preview
    post,
    userIds
  );

  return {
    valid,
    sendRequest: sendPushRequest,
    message,
    setMessage,
  };
};

export const useDeliveryChannels = (
  initialCheckedChannels: Partial<CheckedChannelsState>,
  users: User[]
): {
  isChannelSelected: (channel: ChannelName) => boolean;
  isChannelEnabled: (channel: ChannelName) => boolean;
  toggleChannel: (channel: ChannelName) => void;
  emailChannel: EmailChannel;
  notificationCenterChannel: NotificationCenterChannel;
  pushChannel: PushChannel;
} => {
  const userIds = users.map(({ id }) => id);

  const { checked, toggleChecked } = useChannelSelectionState({
    initialCheckedChannels: {
      email: false,
      push: false,
      notification_center: false,
      ...initialCheckedChannels,
    },
  });

  const enabledChannels = useFeatureFlag('emailOnly')
    ? ['email']
    : Object.keys(initialCheckedChannels);

  const toggleChannel = (channel: ChannelName) => {
    if (!isChannelEnabled(channel)) return;
    toggleChecked(channel);
  };

  const isChannelEnabled = (channel: ChannelName) =>
    enabledChannels.includes(channel);

  const isChannelSelected = (channel: ChannelName) =>
    isChannelEnabled(channel) && checked(channel);

  return {
    isChannelSelected,
    isChannelEnabled,
    toggleChannel,
    emailChannel: {
      name: 'email',
      ...useEmailChannel(userIds),
    },
    notificationCenterChannel: {
      name: 'notification_center',
      ...useNotificationCenterChannel(),
    },
    pushChannel: {
      name: 'push',
      ...usePushChannel(userIds),
    },
  };
};
