import React from 'react';
import {
  Journey,
  JourneyMode,
  Step,
  Steps,
  isStepType,
  AddableStepTypes,
  DecisionEdge,
  JourneyGraph,
  MetricsMode,
  editableJourneyStates,
} from 'models/journeys/journey';
import { JourneyErrors } from 'models/journeys/journey-errors';
import { DrawerState } from 'App/Program/Main/Journey/JourneyDrawer/drawer';
import { ContentListState } from 'App/Program/Main/Journey/JourneyContentListDrawer/Drawer';
import { Position } from 'reactflow';
import {
  publishJourney,
  upsertJourney,
  useValidateJourney,
  validateJourney,
} from 'services/api-journey';
import { useMutation, useQueryClient } from 'react-query';
import { ValidationError } from 'services/Errors/ValidationError';
import {
  journeysKeys,
  useUpsertJourneyMutation,
} from 'hooks/journeys/journeys';
import { DateTime } from 'luxon';
import { includes } from 'utility/objectUtils';
import { useProgram } from 'contexts/program';
import {
  BLOCKED_MOVE_TYPES,
  JourneyReducerAction,
  useJourneyReducer,
} from './journey-reducer';

type persistJourneyProps = {
  onSuccess: (journey: Journey) => void;
  onError: (error: ValidationError) => void;
};

type publishJourneyProps = {
  onSuccess: (name: Journey['name']) => void;
  onError: (error: Error) => void;
};

export type JourneyContextData = {
  journey?: Journey;
  currentGraph?: JourneyGraph;
  drawerState: DrawerState;
  journeyMode: JourneyMode;
  contentListState: ContentListState;
  activeStep?: Step;
  topologyEditable: boolean;
  canMoveStep: (
    id: string,
    direction: Position.Left | Position.Right
  ) => boolean;
  persistJourney: (props: persistJourneyProps) => void;
  updateJourney: (journey: Journey) => void;
  updateDraftGraph: (graph: JourneyGraph) => void;
  publishJourney: (props: publishJourneyProps) => void;
  updateName: (name: string) => void;
  insertDefaultStep: (
    type: AddableStepTypes,
    sourceId: string,
    targetId: string
  ) => void;
  moveStep: (id: string, direction: Position.Left | Position.Right) => void;
  updateStep: (step: Step) => void;
  getStep: <T extends keyof Steps>(
    type: T,
    stepId: string
  ) => Steps[T] | undefined;
  deleteStep: (id: string) => void;
  deleteDecisionOption: (step: Step, edge: DecisionEdge) => void;
  setActiveStepId: (stepId: string) => void;
  setDrawerState: (state: DrawerState) => void;
  setContentListState: (state: ContentListState) => void;
  setMetricsMode: (mode: MetricsMode) => void;
  validate: (j: Journey) => void;
  errors?: JourneyErrors;
  setErrors: (e: JourneyErrors) => void;
  lastModified?: DateTime;
  lastSaveSuccess?: DateTime;
  isSaving?: boolean;
  isPublishing?: boolean;
  isValidating?: boolean;
  metricsMode: MetricsMode;
  showErrorsList: boolean;
  setShowErrorsList: (state: boolean) => void;
  draftCreatingProcess: boolean;
  setDraftCreatingProcess: (state: boolean) => void;
};

const contextPrototype: JourneyContextData = {
  journey: {
    id: 1,
    version: 1,
    name: '',
    state: 'initial',
    draftGraph: {
      rootStepId: '1',
      isLive: false,
      steps: [],
      executionState: 'initial',
    },
    liveGraph: undefined,
    updatedAt: DateTime.now(),
  },
  drawerState: DrawerState.Closed,
  journeyMode: JourneyMode.Edit,
  topologyEditable: true,
  canMoveStep: () => false,
  contentListState: ContentListState.Closed,
  persistJourney: () => {},
  publishJourney: () => {},
  updateJourney: () => {},
  updateDraftGraph: () => {},
  updateName: () => {},
  insertDefaultStep: () => {},
  moveStep: () => {},
  updateStep: () => {},
  getStep: () => undefined,
  deleteStep: () => {},
  deleteDecisionOption: () => {},
  setActiveStepId: () => {},
  setDrawerState: () => {},
  setContentListState: () => {},
  setMetricsMode: () => {},
  validate: () => {},
  errors: {},
  setErrors: () => {},
  isSaving: false,
  metricsMode: MetricsMode.members,
  showErrorsList: false,
  setShowErrorsList: () => {},
  draftCreatingProcess: false,
  setDraftCreatingProcess: () => {},
};

const JourneyContext = React.createContext<JourneyContextData>(
  contextPrototype
);

export const JOURNEY_ACTION_DISABLED_MESSAGE =
  'This action is not yet supported in published Journeys';

export const useJourneyState = (): JourneyContextData => {
  const context = React.useContext(JourneyContext);

  if (context === undefined) {
    throw new Error(
      'Journey context hooks require a containing JourneyProvider'
    );
  }

  return context;
};

export const JourneyProvider: React.FC<{
  journey: Journey;
  mode: JourneyMode;
}> = ({ children, journey, mode: journeyMode }) => {
  const [currentJourney, dispatch] = useJourneyReducer(
    journey,
    lastModifiedMiddleware,
    resolveErrorsMiddleware
  );

  const [lastModified, setLastModified] = React.useState<DateTime | undefined>(
    undefined
  );

  const [showErrorsModal, setShowErrorsModal] = React.useState(false);
  const [draftCreatingProcess, setDraftCreatingProcess] = React.useState(false);

  const [lastSaveSuccess, setLastSaveSuccess] = React.useState<
    DateTime | undefined
  >(journey?.updatedAt ? journey?.updatedAt : undefined);

  const [activeStepId, setActiveStepId] = React.useState<string>();
  const [drawerState, setDrawerState] = React.useState<DrawerState>(
    DrawerState.Closed
  );
  const [contentListState, setContentListState] = React.useState<
    ContentListState
  >(ContentListState.Closed);

  const { id: programId } = useProgram();

  const [errors, setErrors] = React.useState<JourneyErrors>();
  const [metricsMode, setMetricsMode] = React.useState<MetricsMode>(
    MetricsMode.members
  );

  function lastModifiedMiddleware(next: React.Dispatch<JourneyReducerAction>) {
    return (action: JourneyReducerAction) => {
      next(action);
      setLastModified(DateTime.now());
    };
  }

  function resolveErrorsMiddleware(next: React.Dispatch<JourneyReducerAction>) {
    return (action: JourneyReducerAction) => {
      next(action);
      if (action.type === 'step/deleted') {
        setErrors((errs) => {
          if (!errs) return errs;
          const newErrors = { ...errs };

          if (newErrors?.graph?.[action.stepId]) {
            delete newErrors.graph[action.stepId];
          }

          return newErrors;
        });
      }
    };
  }
  const isEditable =
    journey?.state && includes(editableJourneyStates, journey?.state);
  const currentGraph =
    journeyMode === JourneyMode.Edit || !isEditable
      ? currentJourney?.draftGraph
      : currentJourney?.liveGraph;

  const handleSetCurrentJourney = React.useCallback(
    (j: Journey) => {
      dispatch({ type: 'journey/updated', journey: j });
    },
    [dispatch]
  );

  function findStep(stepId: string) {
    return currentGraph?.steps.find((s) => s.id === stepId);
  }

  const findPreviousStep = (id: string) =>
    currentGraph?.steps.find((s) =>
      s.next.some((edge) => edge.targetId === id)
    );

  function canMoveStep(id: string, direction: Position.Left | Position.Right) {
    if (direction === Position.Left) {
      const prevStep = findPreviousStep(id);
      return !!prevStep && !BLOCKED_MOVE_TYPES.includes(prevStep.type);
    }

    const currentStep = findStep(id);
    if (!currentStep) return false;
    if (currentStep.next.length !== 1) return false;

    const nextStep = findStep(currentStep.next[0].targetId);
    return !!nextStep && !BLOCKED_MOVE_TYPES.includes(nextStep.type);
  }

  const validate = React.useCallback(
    async (j) => {
      const response = await validateJourney({ programId, journey: j });
      setErrors(response.errors);
    },
    [programId]
  );

  const {
    errors: responseErrors,
    isLoading: isValidating,
  } = useValidateJourney({ programId, journey });

  const [prevError, setPrevError] = React.useState(responseErrors);
  if (JSON.stringify(prevError) !== JSON.stringify(responseErrors)) {
    setErrors(responseErrors);
    setPrevError(responseErrors);
  }

  const updateName = (name: string) => {
    if (!currentJourney) return;
    handleSetCurrentJourney({ ...currentJourney, name });
  };

  const handleSetActiveStepId = (stepId: string) => {
    setActiveStepId(stepId);
    if (drawerState === DrawerState.Closed) {
      setDrawerState(DrawerState.Partial);
    }
  };

  const handleSetDrawerState = (newState: DrawerState) => {
    setDrawerState(newState);

    // Clear the active step if both drawers are closed
    if (
      newState === DrawerState.Closed &&
      contentListState === ContentListState.Closed
    ) {
      setActiveStepId(undefined);
    }

    // Close the content list drawer if the configuration drawer is open
    if (newState !== DrawerState.Closed) {
      setContentListState(ContentListState.Closed);
    }
  };

  const handleSetContentListState = (newState: ContentListState) => {
    setContentListState(newState);

    if (newState === ContentListState.Closed && activeStepId !== undefined) {
      const activeStep = activeStepId ? findStep(activeStepId) : undefined;
      const stepHasDesign =
        activeStep?.type === 'communication' && !!activeStep.designId;

      if (!stepHasDesign) {
        // clear the active step and close the drawer if the content list is closed and the active step does not have a design
        setActiveStepId(undefined);
        setDrawerState(DrawerState.Closed);
      } else {
        // re-open the drawer if the content list is closed and there is an active step with a design
        setDrawerState(DrawerState.Partial);
      }
    }

    // Close the configuration drawer if the content list drawer is open
    if (newState === ContentListState.Open) {
      setDrawerState(DrawerState.Closed);
    }
  };

  const handleSetMetricsMode = (newMetricsMode: MetricsMode) => {
    setMetricsMode(newMetricsMode);
  };

  const getStep = <T extends keyof Steps>(
    type: T,
    stepId: string
  ): Steps[T] | undefined => {
    const step = findStep(stepId);

    if (!isStepType(step, type)) {
      return undefined;
    }

    return step;
  };

  const {
    mutate: mutatePersist,
    isLoading: isSaving,
  } = useUpsertJourneyMutation({
    onSuccess: (response) => {
      handleSetCurrentJourney(response);
      setLastSaveSuccess(DateTime.now());
      handleSetDrawerState(DrawerState.Closed);
      if (response?.draftGraph?.executionState === 'verifying') {
        validate(response);
      }
    },
  });

  const persistJourney = React.useCallback(
    ({ onSuccess, onError }) => {
      if (currentJourney) {
        mutatePersist(
          { programId, journey: currentJourney },
          {
            onSuccess,
            onError,
          }
        );
      }
    },
    [currentJourney, mutatePersist, programId]
  );

  const handlePublishJourney = React.useCallback(
    async (j: Journey) => {
      const response = await publishJourney({ programId, journey: j });
      if (response.errors) {
        setErrors(response.errors);
      } else {
        setErrors(undefined);
      }
      return response;
    },
    [programId]
  );

  const { mutate: mutatePublish, isLoading: isPublishing } = useMutation(
    handlePublishJourney
  );

  const handlePersistAndPublish = React.useCallback(
    async (j) => {
      const response = await upsertJourney({ programId, journey: j });
      handleSetCurrentJourney(response);
      handlePublishJourney(response);
      return response;
    },
    [handlePublishJourney, handleSetCurrentJourney, programId]
  );

  const onPublishError = React.useCallback((onErrorPayload: Error) => {
    const publishErrors = JSON.parse(onErrorPayload.message) as JourneyErrors;
    if (publishErrors.graph) {
      setErrors(publishErrors);
    } else {
      setErrors(undefined);
    }
  }, []);

  const queryClient = useQueryClient();
  const persistAndPublishJourney = React.useCallback(
    ({
      onSuccess,
      onError,
    }: {
      onSuccess: (name: Journey['name']) => void;
      onError: (err: Error) => void;
    }) => {
      if (currentJourney && currentJourney.id) {
        mutatePublish(currentJourney, {
          onSuccess: () => {
            queryClient.invalidateQueries([...journeysKeys.all]);
            onSuccess(currentJourney.name);
          },
          onError: (param) => {
            if (!(param instanceof Error)) {
              return;
            }
            onPublishError(param);
            onError(param);
          },
        });
      } else {
        handlePersistAndPublish(currentJourney);
      }
    },
    [
      currentJourney,
      mutatePublish,
      onPublishError,
      queryClient,
      handlePersistAndPublish,
    ]
  );

  const updateDraftGraph = (newGraph: JourneyGraph) => {
    if (currentJourney) {
      handleSetCurrentJourney({
        ...currentJourney,
        draftGraph: newGraph,
      });
    }
  };

  const value: JourneyContextData = {
    journey: currentJourney,
    currentGraph,
    drawerState,
    journeyMode,
    topologyEditable:
      journeyMode === JourneyMode.Edit && !currentJourney?.liveGraph,
    contentListState,
    activeStep: activeStepId ? findStep(activeStepId) : undefined,
    updateJourney: handleSetCurrentJourney,
    updateDraftGraph,
    updateName,
    updateStep: (step) => dispatch({ type: 'step/updated', step }),
    deleteStep: (id) => dispatch({ type: 'step/deleted', stepId: id }),
    deleteDecisionOption: (step, edge) => {
      dispatch({ type: 'step/decision/deleted', stepId: step.id, edge });
    },
    canMoveStep,
    insertDefaultStep: (type, sourceId, targetId) =>
      dispatch({
        type: 'step/inserted',
        stepType: type,
        sourceId,
        targetId,
      }),
    persistJourney,
    publishJourney: persistAndPublishJourney,
    moveStep: (id, direction) =>
      dispatch({ type: 'step/moved', stepId: id, direction }),
    setActiveStepId: handleSetActiveStepId,
    setDrawerState: handleSetDrawerState,
    setContentListState: handleSetContentListState,
    setMetricsMode: handleSetMetricsMode,
    getStep,
    validate,
    errors,
    setErrors,
    lastModified,
    lastSaveSuccess,
    isSaving,
    isPublishing,
    isValidating,
    metricsMode,
    showErrorsList: showErrorsModal,
    setShowErrorsList: setShowErrorsModal,
    draftCreatingProcess,
    setDraftCreatingProcess,
  };

  return (
    <JourneyContext.Provider value={value}>{children}</JourneyContext.Provider>
  );
};
