import { DateTime } from 'luxon';
import { ImageData } from 'models/image';
import { MAX_NOTIFICATION_LENGTH } from 'models/notification';
import { LiquidVariable } from 'models/liquid-variable';
import { Content, defaultContent } from '../content';
import {
  defaultBlocks,
  DefinitionBlock,
  InputVariableFieldData,
  InputVariableRef,
  isBooleanVariableValue,
  isInputVariableFieldData,
  isNumberVariableValue,
  isSimpleBlock,
  isStringVariableValue,
  isVideoBlock,
  RenderingVariables,
  Styling,
} from './block';
import { BASE_STYLING } from './style';
import { defaultSettings, Settings } from './settings';
import {
  CallToAction,
  canSummaryHoldAllContentWithCustomCover,
  defaultCallToAction,
  extractCoverSummary,
  extractCoverTitle,
  extractImages,
  filterEmailLinkBlock,
} from './call-to-action';

export type ViewType = 'mobile' | 'web';
export type Instance = { id: string; block: DefinitionBlock };
export type Instances = Array<Instance>;

export type PostMeta = {
  editable: boolean;
};

export type Post = {
  settings: Settings;
  content: Content;
  blocks: DefinitionBlock[]; // ordered by design, top to bottom
  callToAction: CallToAction;
  styles: Styling;
  meta: PostMeta;
  liquidVariables?: LiquidVariable[];
};

export const defaultPostMeta: PostMeta = {
  editable: true,
};

export const defaultPost: Post = {
  settings: defaultSettings,
  content: defaultContent,
  blocks: defaultBlocks,
  callToAction: defaultCallToAction,
  styles: BASE_STYLING,
  meta: defaultPostMeta,
};

export function isScheduled(settings: Settings): boolean {
  return !!settings.publishedAt && settings.publishedAt > DateTime.now();
}

export function isSimplePost(post: Post): boolean {
  const blocks = filterEmailLinkBlock(post.blocks);
  return blocks.length === 1 && isSimpleBlock(blocks[0]);
}

export function isSimpleVideoPost(post: Post): boolean {
  const blocks = filterEmailLinkBlock(post.blocks);
  return blocks.length === 1 && isVideoBlock(blocks[0]);
}

export function isEditable(post?: Post): boolean {
  return !!post?.meta?.editable;
}

export function isPrePublish(post: Post): boolean {
  return (
    post.content.publicationState === 'draft' || isScheduled(post.settings)
  );
}

// TODO: What is the single source of truth for this?
export function isRetryEnabled(post: Post): boolean {
  return post.settings.retries !== 0;
}

function updatePreferCustomCover(
  postBeforeChange: Post,
  change: {
    post: Partial<Post>;
    varsFromPost: (post: Post) => RenderingVariables;
  }
): Post {
  const updatedPost = { ...postBeforeChange, ...change.post };
  if (
    !!change.post.blocks &&
    !change.post.callToAction &&
    isSimplePost(updatedPost) &&
    canSummaryHoldAllContentWithCustomCover(postBeforeChange.blocks) &&
    !canSummaryHoldAllContentWithCustomCover(updatedPost.blocks)
  ) {
    return {
      ...updatedPost,
      callToAction: {
        ...updatedPost.callToAction,
        preferCustom: isSimpleVideoPost(updatedPost),
      },
    };
  }
  return updatedPost;
}

export function updateCallToAction(
  post: Post,
  variables?: RenderingVariables
): Post {
  const customAvailable = isSimplePost(post);
  const customEnabled = customAvailable && post.callToAction.preferCustom;

  const title = post.callToAction.useTextFromContent
    ? extractCoverTitle(post.blocks)
    : post.callToAction.title;

  const summary = post.callToAction.useTextFromContent
    ? extractCoverSummary(post.blocks)
    : post.callToAction.summary;

  const extractImageFromBlocks =
    post.callToAction.useTextFromContent || customEnabled;
  const image = extractImageFromBlocks
    ? extractImages(post.blocks, variables)[0]
    : post.callToAction.image;

  if (
    extractImageFromBlocks &&
    image &&
    image.url === post.callToAction.image?.url &&
    !image.imageId
  ) {
    // If the extracted image from the content blocks is the default grey placeholder image,
    // it will not have an imageId. If we've already saved the post with this cover image,
    // use the imageId from the saved cover image.
    image.imageId = post.callToAction.image?.imageId;
  }

  return {
    ...post,
    callToAction: {
      ...post.callToAction,
      image,
      title,
      summary,
      customAvailable,
      customEnabled,
    },
  };
}

function updateContentAuthor(post: Post): Post {
  return {
    ...post,
    content: {
      ...post.content,
      contentAuthor: post.settings.contentAuthor,
    },
  };
}

export function updateFirstNotification(post: Post): Post {
  const {
    callToAction,
    settings: { notifications },
  } = post;

  if (notifications[0].persisted) {
    return post;
  }

  const trimmedTitle = callToAction.title.substring(0, MAX_NOTIFICATION_LENGTH);
  const trimmedSummary = callToAction.summary.substring(
    0,
    MAX_NOTIFICATION_LENGTH
  );

  const firstNotification = {
    ...notifications[0],
    text: trimmedTitle,
    previewText: trimmedSummary,
    pushText: trimmedTitle,
    notificationCenterText: trimmedTitle,
    notificationCenterMarkAsImportant: false,
  };

  return {
    ...post,
    settings: {
      ...post.settings,
      notifications: [firstNotification, ...notifications.slice(1)],
    },
  };
}

function updateDisplaySettings(post: Post): Post {
  const customAvailable = isSimplePost(post);
  const customEnabled = customAvailable && post.callToAction.preferCustom;
  // simple link posts should take the user directly to the link when opened
  const displayInternalContent = !(
    customEnabled && filterEmailLinkBlock(post.blocks)[0].name === 'links'
  );

  return {
    ...post,
    settings: {
      ...post.settings,
      displaySettings: {
        ...post.settings.displaySettings,
        displayInternalContent,
      },
    },
  };
}

function updateBlockFieldVariables(
  post: Post,
  varsFromPost: (post: Post) => RenderingVariables
): Post {
  const vars = varsFromPost(post);
  return {
    ...post,
    blocks: post.blocks.map((block) => {
      const refs: InputVariableRef[] = [];
      block.fields.forEach((field) => {
        if (
          isStringVariableValue(field) ||
          isBooleanVariableValue(field) ||
          isNumberVariableValue(field)
        ) {
          // TODO we need an isInputVariableRef that can verify a string as
          //      an actual recognized ref.
          refs.push(field.name as InputVariableRef);
        }
      });

      refs.forEach((ref) => {
        const field = block.field_data[ref];
        if (isInputVariableFieldData(field)) {
          if (field.ref === ref) {
            // eslint-disable-next-line no-param-reassign
            (block.field_data[ref] as InputVariableFieldData).value =
              vars[ref] ?? '';
          }
        }
      });
      return block;
    }),
  };
}

export function processChange(
  postBeforeChange: Post,
  change: {
    post: Partial<Post>;
    varsFromPost: (post: Post) => RenderingVariables;
  }
): Post {
  const change1 = updatePreferCustomCover(postBeforeChange, change);
  const change2 = updateCallToAction(change1, change.varsFromPost(change1));
  const change3 = updateDisplaySettings(change2);
  const change4 = updateContentAuthor(change3);
  const change5 = updateFirstNotification(change4);
  return updateBlockFieldVariables(change5, change.varsFromPost);
}

export function cropImage(
  url: string,
  view: ViewType,
  blockType: string,
  isSimple: boolean
): string {
  const baseUrl = process.env.REACT_APP_IMAGE_TRANSFORM_URL;
  const videoTransform = process.env.REACT_APP_VIDEO_PREVIEW_TRANSFORM_PRESET;
  const imageTransform = process.env.REACT_APP_IMAGE_TRANSFORM_PRESET;
  const type = isSimple ? blockType : 'article';
  const isVideo = type === 'video';

  // Cloudinary will reject a URL larger than 255 characters
  if (url.length > 255) return url;
  if (!baseUrl) return url;
  if (isVideo && !videoTransform) return url;
  if (!isVideo && !imageTransform) return url;

  const preset = isVideo ? videoTransform : imageTransform;
  const defaultWebTransform = 'b_transparent,c_pad,f_auto,h_768,q_auto,w_1024';
  const transformationMap: {
    mobile: {
      [key: string]: string;
    };
    web: {
      [key: string]: string;
    };
  } = {
    mobile: {
      article: 'c_fill,g_auto,h_275,q_auto,w_462',
      links: 'c_fill,g_auto,h_275,q_auto,w_462',
      social: 'c_fill,g_auto,h_275,q_auto,w_462',
      single_image: 'c_fill,g_auto,h_346,q_auto,w_462',
      images: 'c_fill,g_auto,h_346,q_auto,w_462',
      video: 'c_fill,g_auto,h_275,q_auto,w_462',
    },
    web: {
      article: defaultWebTransform,
      links: defaultWebTransform,
      social: defaultWebTransform,
      single_image: defaultWebTransform,
      images: defaultWebTransform,
      video: defaultWebTransform,
    },
  };

  return `${baseUrl}/${preset}/${
    transformationMap[view][type]
  }/${encodeURIComponent(url)}`;
}

export function previewImage(
  post: Post,
  view: ViewType,
  cta: CallToAction,
  instances: Instances
): ImageData | undefined {
  const isSimple = isSimplePost(post) && cta.customEnabled;
  const { image } = cta;

  const instance = instances.find(({ block }) => block.name !== 'email_link');
  if (!image || !instance) return undefined;

  return {
    url: cropImage(image.url, view, instance.block.name, isSimple),
    processed: image.processed,
  };
}

export function customCoverLabel(post: Post): string | undefined {
  if (!isSimplePost(post)) return undefined;

  const labelsMap: { readonly [key: string]: string } = {
    single_image: 'Image',
    images: 'Images',
    video: 'Video',
    links: 'Link',
  };

  return labelsMap[filterEmailLinkBlock(post.blocks)[0].name];
}

export const buildPreviewPermalink = (post: Post): string | undefined => {
  if (post.content.publicationState === 'published')
    return post.content.permalinkUrl;

  const baseUrl = process.env.REACT_APP_KAI_DOMAIN;
  return baseUrl
    ? `${baseUrl}/${post.content.meta.programId}/edit/publisher/${post.content.id}/link-preview`
    : undefined;
};
