import { DateTime, Interval } from 'luxon';
import { identityFn } from 'utility/data-extractor';

import { useProgram } from 'contexts/program';
import { useContextCommunication } from 'hooks/context_communication';
import {
  personalizationVariableRegExp,
  personalizationVariableRegExpOpen,
} from 'models/liquid-variable';
import { buildDuration, Duration, UnitValue } from './duration';
import { Channel } from './channel';

// TODO: Move to util?
// TODO: There's a way to DRY this
function maxBy<T>(arr: Array<T>, by: (x: T) => number) {
  const result = arr.map((el) => {
    return by(el);
  });
  const max = Math.max.apply(null, result);
  return arr[result.indexOf(max)];
}

function minBy<T>(arr: Array<T>, by: (x: T) => number) {
  const result = arr.map((el) => {
    return by(el);
  });
  const min = Math.min.apply(null, result);
  return arr[result.indexOf(min)];
}

export const MAX_NOTIFICATION_LENGTH = 120;

export type Notification = {
  text: string;
  previewText?: string;
  pushText?: string;
  notificationCenterText?: string;
  notificationCenterMarkAsImportant?: boolean;
  order: number;
  uuid?: string;
  dateTime?: DateTime;
  isPublishDateTime?: boolean;
  persisted?: boolean;
};

export const defaultFirstNotification: Notification = {
  text: '',
  order: 1,
  persisted: false,
};

export const isNotification = (
  notification: Notification | unknown
): notification is Notification => {
  const notif = notification as Notification;
  return (
    notif &&
    typeof notif.text === 'string' &&
    typeof notif.order === 'number' &&
    (notif.previewText == null || typeof notif.previewText === 'string') &&
    (notif.pushText == null || typeof notif.pushText === 'string') &&
    (notif.uuid == null || typeof notif.uuid === 'string') &&
    (notif.dateTime == null || DateTime.isDateTime(notif.dateTime)) &&
    (notif.isPublishDateTime == null ||
      typeof notif.isPublishDateTime === 'boolean')
  );
};

export const isNotificationsArray = (
  notifications: Parameters<typeof isNotification>[0][]
): notifications is Notification[] => {
  return notifications.every(isNotification);
};

// TODO add better parsing for liquid variables.
// Regex go burrrrr
export const areVariablesValid = (
  text: string,
  allowedVariables: string[]
): boolean => {
  const group = text.match(personalizationVariableRegExp);
  const groupOpen = text.match(personalizationVariableRegExpOpen);

  if (!group) {
    return !groupOpen;
  }
  const groupIndex = group.index ? group.index : 0;

  const variable = group[1];
  const foundInAllowed = allowedVariables.find((v) => v === variable);
  if (!foundInAllowed) {
    return false;
  }

  const restOfText = text.substring(groupIndex + group[0].length);
  if (restOfText) {
    return areVariablesValid(restOfText, allowedVariables);
  }

  return true;
};

export const isNotificationValid = (
  notification: Notification | unknown,
  allowedVariables: string[],
  enabledChannels: Channel[]
): boolean => {
  if (!isNotification(notification)) return false;

  const textValid = areVariablesValid(notification.text, allowedVariables);
  const pushTextValid =
    !notification.pushText ||
    areVariablesValid(notification.pushText, allowedVariables);
  const previewTextValid =
    !notification.previewText ||
    areVariablesValid(notification.previewText, allowedVariables);

  return (
    validateTextsPresence(notification, enabledChannels) &&
    textValid &&
    pushTextValid &&
    previewTextValid
  );
};

export const validateTextsPresence = (
  notification: Notification,
  enabledChannels: Channel[]
): boolean => {
  const { text, previewText, pushText, notificationCenterText } = notification;
  const textLen = text.trim().length;
  const previewTextLen = previewText == null ? 0 : previewText.trim().length;
  const pushTextLen = pushText == null ? 0 : pushText.trim().length;
  const notificationCenterTextLen =
    notificationCenterText == null ? 0 : notificationCenterText.trim().length;

  return enabledChannels.every((channel) => {
    switch (channel) {
      case 'email':
        return (
          textLen > 0 &&
          textLen <= MAX_NOTIFICATION_LENGTH &&
          previewTextLen <= MAX_NOTIFICATION_LENGTH
        );
      case 'push':
        return pushTextLen > 0 && pushTextLen <= MAX_NOTIFICATION_LENGTH;
      case 'assistant':
        return (
          notificationCenterTextLen > 0 &&
          notificationCenterTextLen <= MAX_NOTIFICATION_LENGTH
        );
      default:
        return true;
    }
  });
};

export const areNotificationsValid = (
  notifications: Array<Notification | unknown>,
  allowedVariables: string[],
  enabledChannels: Channel[]
): boolean => {
  const validationFunc = (n: Notification | unknown) =>
    isNotificationValid(n, allowedVariables, enabledChannels);
  return notifications.map(validationFunc).every(identityFn);
};

export const useNotificationValidator = (
  notifications: Notification[],
  enabledChannels: Channel[]
): boolean => {
  const { id: programId } = useProgram();

  const { data: communicationMentionsData } = useContextCommunication(
    programId,
    false
  );

  return areNotificationsValid(
    notifications,
    (communicationMentionsData || []).map((c) => c.key),
    enabledChannels
  );
};

export const sortNotifications = (
  notifications: Notification[]
): Notification[] => {
  const newNotifications = [...notifications];

  return newNotifications.sort(sortByDateTime).map(reorderByIndex);
};

// Note: Non-date Notifications should always go last
const sortByDateTime = (a: Notification, b: Notification): number => {
  if (a?.dateTime && b?.dateTime) {
    return a.dateTime.toMillis() - b.dateTime.toMillis();
  }
  if (!a?.dateTime && b?.dateTime) {
    return 1;
  }
  if (!a?.dateTime && !b?.dateTime) {
    return 0;
  }
  return -1;
};

const reorderByIndex = (n: Notification, i: number): Notification => ({
  ...n,
  order: i + 1,
});

type NotificationDateParts = Notification & {
  dateTime: DateTime;
};

export const calculateDuration = (notifications: Notification[]): Duration => {
  if (notifications.length < 2) {
    // TODO: this is prob not the place to do this....
    throw Error('Cannot calculate duration on a single Notification');
  }

  const newNotifications = [...notifications];

  const notificationDates = newNotifications.filter(
    (n) => n?.dateTime
  ) as NotificationDateParts[];

  const maxNotif = maxBy(notificationDates, (n) => n.dateTime.toMillis());
  const minNotif = minBy(notificationDates, (n) => n.dateTime.toMillis());

  // add an extra day for current day
  const days = Interval.fromDateTimes(
    minNotif.dateTime,
    maxNotif.dateTime
  ).length('days');
  const durationValue = Math.round(days) + 1;
  return buildDuration(durationValue, 'day' as UnitValue);
};

export const initializeNotificationsDateArray = (
  notifications: Notification[],
  publishedAt: DateTime
): Notification[] => {
  const newNotifications = [...notifications];
  const firstNotif = newNotifications[0];

  if (!firstNotif?.dateTime) {
    const newNotif = updateDateTime(firstNotif, publishedAt, true);
    return [newNotif].concat(newNotifications.slice(1));
  }
  return notifications;
};

export const updateDateTime = (
  notification: Notification,
  dateTime: DateTime,
  isPublishDateTime: boolean
): Notification => {
  return {
    ...notification,
    dateTime,
    isPublishDateTime,
  };
};
