import { reassignStepEdge } from 'App/Program/Main/Journey/JourneyCanvas/utils/graph';
import {
  AddableStepTypes,
  CommunicationStep,
  DecisionEdge,
  DecisionStep,
  DEFAULT_EDGE_TARGET_ID,
  DelayStep,
  getDefaultStep,
  Journey,
  Step,
  Steps,
} from 'models/journeys/journey';
import { useReducer } from 'react';
import { Position } from 'reactflow';
import { asserts } from 'utility/asserts';

type JourneyDispatchMiddleware = (
  next: React.Dispatch<JourneyReducerAction>
) => React.Dispatch<JourneyReducerAction>;

export function useJourneyReducer(
  initialJourney: Journey,
  ...middleware: JourneyDispatchMiddleware[]
): readonly [Journey, React.Dispatch<JourneyReducerAction>] {
  const [journey, d] = useReducer(journeyReducer, initialJourney);

  return [journey, wrapDispatch(d, ...middleware)] as const;
}

export type JourneyReducerAction =
  | {
      type: 'step/updated';
      step: Step;
    }
  | {
      type: 'step/deleted';
      stepId: Step['id'];
    }
  | {
      type: 'step/moved';
      stepId: Step['id'];
      direction: Position.Left | Position.Right;
    }
  | {
      type: 'step/inserted';
      stepType: AddableStepTypes;
      /*
       * The targetId is the step that the new step will be inserted before.
       * This targetId will be the next step of the inserted step.
       */
      targetId: string;
      /* The sourceId is the edge that the new step is inserted on */
      sourceId: string;
    }
  | {
      type: 'step/decision/deleted';
      stepId: Step['id'];
      edge: DecisionEdge;
    }
  | {
      type: 'step/communication/template-selected';
      stepId: Step['id'];
      communication: Partial<CommunicationStep>;
    }
  | {
      type: 'journey/updated';
      journey: Journey;
    };

export function journeyReducer(
  state: Journey,
  action: JourneyReducerAction
): Journey {
  const currentGraph = state.draftGraph;
  switch (action.type) {
    /**
     * Updates a specific step in the journey.
     */
    case 'step/updated':
      return updateStep(action.step);

    case 'step/inserted': {
      if (!currentGraph) return state;
      const { sourceId, targetId } = action;
      const [defaultStep, additionalSteps] = getDefaultStep(action.stepType);
      const newStep = insertStepBetween(defaultStep, sourceId, targetId);
      const newRootStepId =
        currentGraph.rootStepId === targetId
          ? defaultStep.id
          : currentGraph.rootStepId;

      const steps = [...newStep, ...additionalSteps];

      return {
        ...state,
        draftGraph: {
          ...currentGraph,
          steps,
          rootStepId: newRootStepId,
        },
      };
    }

    /**
     * Deletes a step from the journey. Handles both decision and non-decision steps.
     */
    case 'step/deleted': {
      const step = findStep(action.stepId);
      if (step.type === 'decision') {
        return deleteDecisionStep(step);
      }
      if (step.type === 'communication' || step.type === 'delay') {
        return deleteNonDecisionStep(step);
      }

      return state;
    }

    /**
     * Deletes a specific decision option within a decision step.
     */
    case 'step/decision/deleted': {
      const step = findStep(action.stepId);
      if (step.type !== 'decision') {
        return state;
      }
      return deleteDecisionOption(step, action.edge);
    }

    /**
     * Moves a step left or right within the journey.
     */
    case 'step/moved': {
      const { direction, stepId } = action;
      if (!canMoveStep(stepId, direction)) {
        return state;
      }

      const currentStep = findStep(stepId);

      if (direction === Position.Left) {
        return moveLeft(currentStep);
      }
      return moveRight(currentStep);
    }

    /**
     * Updates the entire journey.
     */
    case 'journey/updated':
      return action.journey;
    default:
      return state;
  }

  function insertStepBetween(
    step: Step,
    sourceId: string,
    targetId: string
  ): Step[] {
    const source = findStep(sourceId);
    const target = findStep(targetId);

    if (!source || !currentGraph || !target) return []; // Invalid insert, source or target doesn't exist
    if (!source.next.some((edge) => edge.targetId === targetId)) return []; // Invalid insert, edge doesn't exist

    // create new step with edge pointing to target
    const newStep = reassignStepEdge(step, DEFAULT_EDGE_TARGET_ID, targetId);
    const updatedStep = reassignStepEdge(source, targetId, newStep.id);

    return [
      ...currentGraph.steps.map((s) =>
        s.id === updatedStep.id ? updatedStep : s
      ),
      newStep,
    ];
  }

  function moveLeft(currentStep: Step): Journey {
    const previousStep = findPreviousStep(currentStep.id);
    if (!previousStep || !currentGraph) return state;

    let { rootStepId } = currentGraph;
    if (previousStep.id === rootStepId) {
      rootStepId = currentStep.id;
    }

    const previousGrandStep = findPreviousStep(previousStep.id);
    if (!previousGrandStep) return state;

    // when moving left the next step is allowed to not exist
    const nextStep =
      currentStep.next.length === 1
        ? findStep(currentStep.next[0].targetId)
        : undefined;

    // create a new step from currentStep and replace current step's next target to previous step,
    // or set it to `default` if it does not yet have a next target
    const updatedCurrentStep = reassignStepEdge(
      currentStep,
      nextStep ? nextStep.id : DEFAULT_EDGE_TARGET_ID,
      previousStep.id
    );

    // create a new step from previousStep and replace previous step's next target to next step if it exists
    // otherwise set the step's next target to `default`
    const updatedPreviousStep = reassignStepEdge(
      previousStep,
      currentStep.id,
      nextStep ? nextStep.id : DEFAULT_EDGE_TARGET_ID
    );

    // create a new step from previousGrandStep and replace previous grand-step's next target to current step
    const updatedPreviousGrandStep = reassignStepEdge(
      previousGrandStep,
      previousStep.id,
      currentStep.id
    );

    // generate a new set of journey steps replacing the modified steps with the newly created ones
    const updatedSteps = currentGraph.steps.map(
      (s) =>
        [
          updatedCurrentStep,
          updatedPreviousStep,
          updatedPreviousGrandStep,
        ].find((step) => s.id === step?.id) || s
    );

    return {
      ...state,
      draftGraph: {
        ...currentGraph,
        steps: updatedSteps,
        rootStepId,
      },
    };
  }

  function moveRight(currentStep: Step): Journey {
    // moving a step right is the same as moving the next step left
    const nextStep =
      currentStep.next.length === 1
        ? findStep(currentStep.next[0].targetId)
        : undefined;

    if (!nextStep) return state;
    return moveLeft(nextStep);
  }

  function findPreviousStep(id: string) {
    return currentGraph?.steps.find((s) =>
      s.next.some((edge) => edge.targetId === id)
    );
  }

  function canMoveStep(id: string, direction: Position.Left | Position.Right) {
    if (direction === Position.Left) {
      const prevStep = findPreviousStep(id);
      return !!prevStep && !BLOCKED_MOVE_TYPES.includes(prevStep.type);
    }

    const currentStep = findStep(id);
    if (!currentStep) return false;
    if (currentStep.next.length !== 1) return false;

    const nextStep = findStep(currentStep.next[0].targetId);
    return !!nextStep && !BLOCKED_MOVE_TYPES.includes(nextStep.type);
  }

  function deleteDecisionOption(step: Step, edge: DecisionEdge) {
    if (!currentGraph) {
      return state;
    }
    const nodeId = edge.targetId;
    const stepsToDelete: Array<string> = [];
    const next = step.next.filter(
      (s) => s.targetId !== edge.targetId
    ) as DecisionEdge[];
    const newDecisionStep = {
      ...step,
      next,
    };

    function findStepsToDelete(deletedEdgeId: string): void {
      if (currentGraph) {
        const deletedStep = currentGraph.steps.find(
          (s) => s.id === deletedEdgeId
        );
        if (deletedStep) {
          stepsToDelete.push(deletedStep.id);
          if (deletedStep.next) {
            deletedStep.next.forEach((s) => findStepsToDelete(s.targetId));
          }
        }
      }
    }

    findStepsToDelete(nodeId);

    return {
      ...state,
      draftGraph: {
        ...currentGraph,
        steps: currentGraph.steps
          .filter((s) => !stepsToDelete.includes(s.id))
          .map((s) => (s.id === newDecisionStep.id ? newDecisionStep : s)),
      },
    };
  }

  function getAllChildrenIds(st: Step): string[] {
    const toDelete = st.next.flatMap((n) => {
      const sp = state.draftGraph?.steps.find((s) => s.id === n.targetId);
      if (!sp) return [];
      return getAllChildrenIds(sp);
    });

    return [st.id].concat(toDelete);
  }

  function deleteNonDecisionStep(step: CommunicationStep | DelayStep) {
    const stepBefore = currentGraph?.steps.find((s) =>
      s.next.map((n) => n.targetId).includes(step.id)
    );
    const stepAfterId = step.next.length === 1 && step.next[0].targetId;

    if (!stepBefore || !stepAfterId || !currentGraph) return state;

    const newStepBefore = reassignStepEdge(stepBefore, step.id, stepAfterId);

    const newSteps: Step[] = currentGraph.steps
      .filter((s) => s.id !== step.id)
      .map((s) => (s.id === stepBefore.id ? newStepBefore : s));

    const newRootStepId =
      currentGraph.rootStepId === step.id
        ? stepAfterId
        : currentGraph.rootStepId;

    return {
      ...state,
      draftGraph: {
        ...currentGraph,
        steps: newSteps,
        rootStepId: newRootStepId,
      },
    };
  }

  function deleteDecisionStep(step: DecisionStep) {
    const { draftGraph } = state;
    const currentSteps = state.draftGraph?.steps;

    if (!draftGraph || !currentSteps) {
      return state;
    }
    const stepBefore = currentSteps.find((s) =>
      s.next.map((n) => n.targetId).includes(step.id)
    );

    if (!stepBefore) return state;

    const defaultPathEdge = step.next[step.next.length - 1];
    const allOtherPathEdges = step.next.slice(0, -1);

    const childIds = allOtherPathEdges
      .map(({ targetId }) => currentSteps.find((s) => s.id === targetId))
      .filter((s): s is Step => s !== undefined)
      .flatMap(getAllChildrenIds);

    const newStepBefore = reassignStepEdge(
      stepBefore,
      step.id,
      defaultPathEdge.targetId
    );

    const newSteps = draftGraph.steps
      .filter((s) => s.id !== step.id && !childIds.includes(s.id))
      .map((s) => (s.id === stepBefore.id ? newStepBefore : s));

    const newRootStepId =
      draftGraph.rootStepId === step.id
        ? defaultPathEdge.targetId
        : draftGraph.rootStepId;

    return {
      ...state,
      draftGraph: {
        ...draftGraph,
        steps: newSteps,
        rootStepId: newRootStepId,
      },
    };
  }

  function findStep(stepId: string) {
    const step = state.draftGraph?.steps.find((s) => s.id === stepId);
    asserts(step !== undefined, 'Step must exist');
    return step;
  }

  function updateStep(st: Step) {
    const { draftGraph } = state;
    if (!draftGraph) {
      return state;
    }
    return {
      ...state,
      draftGraph: {
        ...draftGraph,
        steps: draftGraph.steps.map((s) => (s.id === st.id ? st : s)),
      },
    };
  }
}

export const BLOCKED_MOVE_TYPES: Array<keyof Steps> = [
  'start',
  'end',
  'decision',
];

/**
 * Wraps a dispatch function with middleware to apply additional logic
 * before and after the dispatch.
 */
function wrapDispatch(
  disp: React.Dispatch<JourneyReducerAction>,
  ...ware: JourneyDispatchMiddleware[]
) {
  let d = disp;
  for (const middleware of ware.reverse()) {
    d = middleware(d);
  }

  return d;
}
