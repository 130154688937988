import { Option } from 'models/insight/json/filterJson';
import React from 'react';
import { FilterDropdown } from 'shared/FilterDropdown';

export const allowableDateRanges = (): string[] => {
  const today = new Date();
  const twoYearsAgo = new Date(today.getFullYear() - 2, today.getMonth(), 1);

  const dateRanges = [];
  for (let d = twoYearsAgo; d <= today; d.setMonth(d.getMonth() + 1)) {
    dateRanges.push(`${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`);
  }

  return dateRanges;
};

type DashboardParameterProps = {
  label: string;
  name: string;
  values: Option[];
  onChange: (name: string, value: string | number | boolean | Date) => void;
};

export const DashboardDateRangeFilter: React.FC<{
  onChange: (name: string, value: string | number | boolean | Date) => void;
}> = ({ onChange }) => {
  const allowedDates = allowableDateRanges();
  const allowedGranularity = ['month', 'year', 'quarter'].map((x) => {
    return {
      label: x,
      value: x,
    };
  });
  const allowedDateMap = allowedDates.map((x) => {
    return {
      label: x,
      value: x,
    };
  });

  return (
    <div>
      <DashboardParameter
        label="Time Range"
        name="time_range_granularity_param"
        values={allowedGranularity}
        onChange={onChange}
      />
      <DashboardParameter
        label="Start Date"
        name="time_range_start_date"
        values={allowedDateMap}
        onChange={onChange}
      />
      <DashboardParameter
        label="End Date"
        name="time_range_end_date"
        values={allowedDateMap}
        onChange={onChange}
      />
    </div>
  );
};

export const DashboardParameter: React.FC<DashboardParameterProps> = ({
  label,
  name,
  values,
  onChange,
}) => {
  const [selectedValue, setSelectedValues] = React.useState<string>();
  const onParameterChanged = React.useCallback(
    (value: string[]) => {
      if (value.length > 1) {
        setSelectedValues(value[value.length - 1]);
        onChange(name, value[value.length - 1]);
      } else {
        setSelectedValues(value[0]);
        onChange(name, value[0]);
      }
    },
    [name, onChange]
  );

  return (
    <FilterDropdown
      onChange={onParameterChanged}
      options={values}
      label={label}
      multiSelect={false}
      selectedOptions={values.filter(
        (opt: Option) => selectedValue === opt.value
      )}
    />
  );
};
