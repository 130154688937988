import { RouteComponentProps, useParams } from '@reach/router';
import cx from 'classnames';
import { useProgram } from 'contexts/program';
import { PageHeader, PageTemplate } from 'DesignSystem/Layout/Pages';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { fetchTableauToken } from 'services/api-insights';
import { DefaultLoading } from 'shared/InfiniteList/DefaultLoading';
import TableauEmbed from '../../../../../components/tableauEmbed';
import { useWorkbookQuery } from '../../../../../hooks/insights/useInsightsPlusApi';
import { DashboardDateRangeFilter } from '../components/DashboardParameter';
import { DownloadWorksheetData } from '../components/DownloadWorksheetData';
import {
  DashboardFilter,
  useDashboardFilters,
} from '../hooks/useDashboardFilters';
import styles from './tableau.module.css';

export type TableauPropsType = {
  workbookId?: string;
  id?: string;
  path: string;
} & RouteComponentProps;

// Preload font which is embedded into the tableau reports to avoid FOUC (flash of unloaded content)
const customFontDomain = `${process.env.REACT_APP_TABLEAU_DOMAIN}/custom-fonts/webfont.html?font=Inter`;

export const Tableau: React.FC<TableauPropsType> = () => {
  const { dashboard_id: dashboardId, workbook_id: workbookId } = useParams();
  const programId = useProgram().id;
  const vizRef = useRef<Tableau.ITableauViz>(null);
  const [initialized, setInitialized] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [contentUrl, setContentUrl] = useState<string>();
  const [tableauVizLoading, setTableauVizLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState();
  const [worksheetToDownload, setWorksheetToDownload] = useState<
    Tableau.Sheet
  >();
  const [activeSheet, setActiveSheet] = useState<Tableau.WorkSheet>();
  const { isLoading: workbookLoading, workbook } = useWorkbookQuery(
    programId,
    workbookId
  );
  const scrollPositionRef = useRef<number>(0);

  const DOWNLOAD_WORKSHEET = 'pagination';

  // Load required token and dashboard for the tableau viz.
  const { isLoading: tokenLoading, isError, data: token } = useQuery(
    ['load-tableauEmbed', programId, workbookId],
    async (): Promise<string> => {
      return fetchTableauToken(programId);
    },
    { cacheTime: 0 }
  );

  const tabs =
    workbook?.views.view.map((db) => ({
      to: `${db.id}`,
      label: db.name,
    })) ?? undefined;

  const activateSheet = React.useCallback((name: string) => {
    vizRef.current?.workbook.activateSheetAsync(name).then((sheet) => {
      if (sheet?.size.maxSize) {
        const styleString = `height: ${sheet.size.maxSize.height}px; width: ${sheet.size.maxSize.width}px; border: none;`;
        // Required to resize iframe when switching between tabs
        // (tableau adds inline styles that have to be overridden)
        vizRef.current?.iframe?.setAttribute('style', styleString);
      }
      document.querySelector('main')?.scrollTo(0, 0);
    });
  }, []);

  // To avoid rerender of entire Viz component, only set the contentUrl load once the workbook is loaded. Tableau
  // does not require it to be updated when switching between dashboards.
  useEffect(() => {
    if (workbook && !initialized) {
      scrollPositionRef.current = window.scrollY;
      setContentUrl(
        workbook?.views.view.find((d) => d.id === dashboardId)?.contentUrl
      );
      setInitialized(true);
    }
  }, [workbook, setContentUrl, initialized, setInitialized, dashboardId]);

  useEffect(() => {
    const dashboard = workbook?.views.view.find((d) => d.id === dashboardId);
    if (!dashboard) return;

    if (selectedTab !== dashboardId) {
      setSelectedTab(dashboardId);
      setTableauVizLoading(true);
    }
    if (!tableauVizLoading) {
      if (vizRef.current?.workbook !== undefined) {
        // switch viz to the currently selected dashboard
        activateSheet(dashboard.name);
      }
    }
  }, [workbook, tableauVizLoading, selectedTab, dashboardId, activateSheet]);

  const onFilterChange = (filter: string, values: string[]) => {
    try {
      setFiltersLoading(true);
      let updateType = 'replace';
      if (!values.length) updateType = 'all';
      vizRef.current?.workbook.activeSheet
        .applyFilterAsync(filter, values, updateType)
        .then(() => {
          setFiltersLoading(false);
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to change filters', e);
      setFiltersLoading(false);
    }
  };

  const onParameterChanged = (
    parameter: string,
    value: string | Date | number | boolean
  ) => {
    try {
      setFiltersLoading(true);
      vizRef.current?.workbook
        .changeParameterValueAsync(parameter, value)
        .then(() => {
          setFiltersLoading(false);
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to change parameter', e);
      setFiltersLoading(false);
    }
  };

  const dashboardFilters = useDashboardFilters(dashboardId);

  useEffect(() => {
    if (activeSheet) {
      const worksheet = activeSheet.worksheets.find(
        (sheet) => sheet.name.toLowerCase().indexOf(DOWNLOAD_WORKSHEET) > -1
      );
      setWorksheetToDownload(worksheet ?? undefined);
    }
  }, [activeSheet, setWorksheetToDownload]);

  // const getParameters = React.useCallback(async () => {
  //   const params = await vizRef.current?.workbook.getParametersAsync();
  // }, []);

  const anyLoading = workbookLoading || tokenLoading;
  const dashboardLoading = !contentUrl || tokenLoading || tableauVizLoading;

  return (
    <PageTemplate title="Dashboard">
      {isError && !anyLoading && (
        <p>Unable to load Dashboard. Please contact system admin.</p>
      )}
      {!isError && (
        <div style={{ paddingLeft: 32, paddingRight: 32 }}>
          <Helmet>
            <script type="text/javascript" src={`${customFontDomain}`} />
          </Helmet>
          <PageHeader
            title={workbook?.name || 'Workbook'}
            breadcrumbs={[
              { label: 'Insights+', to: '../../../..' },
              { label: workbook?.name || 'Workbook' },
            ]}
            actionsOverride={
              worksheetToDownload &&
              activeSheet &&
              vizRef?.current?.workbook && (
                <DownloadWorksheetData
                  worksheetToDownload={worksheetToDownload}
                  workbook={vizRef.current.workbook}
                />
              )
            }
            tabs={tabs}
            filterbar={
              tableauVizLoading ? (
                <></>
              ) : (
                <div className="kai-flex-row">
                  {dashboardFilters.parameters.map(() => (
                    <DashboardDateRangeFilter onChange={onParameterChanged} />
                  ))}
                  {dashboardFilters.filters.map((x) => (
                    <DashboardFilter
                      key={x}
                      filter={x}
                      onChange={onFilterChange}
                    />
                  ))}
                </div>
              )
            }
          />
          {(dashboardLoading || filtersLoading) && <DefaultLoading />}

          <div
            className={cx(styles.tableauVizContainer, {
              [styles.hidden]: dashboardLoading || filtersLoading,
            })}
          >
            {contentUrl && !tokenLoading && (
              <TableauEmbed
                ref={vizRef}
                token={`${token}`}
                src={`${contentUrl}`}
                toolbar="hidden"
                hide-tabs
                iframe-auth
                onFirstInteractive={() => {
                  setTableauVizLoading(false);
                  setActiveSheet(vizRef.current?.workbook.activeSheet);
                }}
                onTabSwitched={() => {
                  setTableauVizLoading(false);
                }}
              />
            )}
          </div>
        </div>
      )}
    </PageTemplate>
  );
};
