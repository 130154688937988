import { TriggerButton } from 'components/content/ContentFilterBar/TriggerButton';
import { OptionType } from 'hooks/common';
import React from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { InfiniteLoadProps, InfiniteSelect } from 'shared/InfiniteSelect';
import styles from './filter-dropdown.module.css';

type PropsType = {
  onChange: (value: string[]) => void;
  onSearchTermChange?: (value: string) => void;
  onClose?: () => void;
  options: OptionType[];
  label: string;
  clearDisabled?: boolean;
  searchEnabled?: boolean;
  searchTerm?: string;
  searchPlaceholder?: string;
  selectedOptions?: OptionType[];
  hasClearSearchButton?: boolean;
  maxHeight?: number;
  multiSelect?: boolean;
} & InfiniteLoadProps;

export const FilterDropdown: React.FC<PropsType> = (props) => {
  const {
    onChange,
    label,
    onSearchTermChange,
    options,
    selectedOptions = [],
    searchEnabled,
    searchPlaceholder,
    searchTerm,
    clearDisabled,
    hasClearSearchButton,
    onClose,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    maxHeight = 200,
    multiSelect = true,
  } = props;
  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const onSelectedIdsChange = React.useCallback(
    (ids: string[]) => {
      onChange(ids);
    },
    [onChange]
  );

  const dropdown = React.useMemo(() => {
    const rowIds = options.map((x) => x.value);
    const renderRow = (value: string) => {
      const lbl = options.find((opt) => opt.value === value)?.label;
      return (
        <div>
          <span className={styles.filterTitle}>{lbl}</span>
        </div>
      );
    };

    const selectedIds = selectedOptions.map(({ value }) => value);

    return (
      <InfiniteSelect
        className={styles.filterItemsWrapper}
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={maxHeight}
        itemHeight={32}
        clearDisabled={clearDisabled}
        hasClearSearchButton={hasClearSearchButton}
        selectedIds={selectedIds}
        searchEnabled={searchEnabled || !!onSearchTermChange}
        searchTerm={searchTerm}
        searchPlaceholder={searchPlaceholder}
        onSearchTermChange={onSearchTermChange}
        onSelectedIdsChange={onSelectedIdsChange}
        itemClassName="filter-item"
        dismissButton="Done"
        onDismissRef={onDismissRef}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        multiSelect={multiSelect}
      />
    );
  }, [
    clearDisabled,
    selectedOptions,
    fetchNextPage,
    hasClearSearchButton,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    maxHeight,
    onSearchTermChange,
    options,
    searchEnabled,
    searchPlaceholder,
    searchTerm,
    onSelectedIdsChange,
    multiSelect,
  ]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  const selectedLabels = selectedOptions.map(
    ({ label: optionLabel }) => optionLabel
  );

  return (
    <div className={styles.dropdownWrapper}>
      <ClickDropdown dropdownRenderProp={dropdownRenderProp}>
        <TriggerButton name={label} values={selectedLabels} onClose={onClose} />
      </ClickDropdown>
    </div>
  );
};
