import React, { useState } from 'react';
import { Icon } from 'shared/Icon';
import { useAudienceCriteriaQuery } from 'hooks/audience';
import { useProgram } from 'contexts/program';
import { emptyExpression, SimpleExpression } from 'models/expression';
import { editableTextToQueryType } from 'models/audience';
import { Box } from 'DesignSystem/Components';
import cx from 'classnames';
import { MenuPlacement } from 'react-select';
import styles from '../AudienceBuilder.module.css';
import { CriterionSelect, isRecognizedCriterion } from '../CriterionSelect';
import { OperatorSelect } from '../OperatorSelect';
import { ValueSelect } from '../ValueSelect';

export const SimpleBuilder: React.FC<{
  onChange: (value: SimpleExpression) => void;
  onDelete: () => void;
  isAlone: boolean;
  value: SimpleExpression;
  canEdit: boolean;
  editableTextToQuery: editableTextToQueryType;
  audienceName?: string;
  hasError?: boolean;
  applyDisabledStyles?: boolean;
  menuPlacement?: MenuPlacement;
  clearLastItem?: boolean;
}> = ({
  onChange,
  onDelete,
  isAlone,
  value,
  canEdit,
  editableTextToQuery,
  audienceName,
  hasError = false,
  applyDisabledStyles = false,
  menuPlacement = 'auto',
  clearLastItem = false,
}) => {
  const { id: programId } = useProgram();
  const { data } = useAudienceCriteriaQuery(programId);

  const lookupCriterion = (name: string | undefined) =>
    data?.find((c) => c.id === name) || { id: name || '', type: '' };

  const [criterion, setCriterion] = useState<{ id: string; type: string }>(
    lookupCriterion(value.criterion)
  );
  const [operator, setOperator] = useState<string>(value.operator);
  const [values, setValues] = useState<string[]>(value.values);
  const [simpleExpression, setSimpleExpression] = useState<SimpleExpression>(
    value
  );

  if (criterion.type !== lookupCriterion(value.criterion).type) {
    setCriterion(lookupCriterion(value.criterion));
  }

  if (simpleExpression !== value) {
    setSimpleExpression(value);
    setCriterion(lookupCriterion(value.criterion));
    setOperator(value.operator);
    setValues(value.values);
  }

  const onChangeCriterion = (newCriterion: string | undefined) => {
    const safeCriterion = lookupCriterion(newCriterion);

    setCriterion(safeCriterion);
    setOperator('');
    setValues([]);

    onChange(
      emptyExpression({
        ...value,
        values: [], // Remove explicitly to avoid keeping old values.
        criterion: safeCriterion?.id || '',
      })
    );
  };

  const onChangeOperator = (newOperator: string | undefined) => {
    const safeOperator = newOperator || 'OR';
    setOperator(safeOperator);

    onChange({
      ...value,
      operator: safeOperator,
    });
  };

  const onChangeValues = (newValues: string[]) => {
    setValues(newValues);

    onChange({
      ...value,
      values: newValues,
    });
  };

  return (
    <div className={styles.simpleExpression}>
      <div
        className={cx(styles.criteriaSelectorLeft, {
          [styles.disabledSelect]: applyDisabledStyles && !canEdit,
        })}
      >
        <CriterionSelect
          value={criterion?.id || ''}
          onChange={onChangeCriterion}
          isDisabled={!canEdit}
          availableCriteria={data || []}
        />
      </div>

      <div
        className={cx(styles.criteriaSelectorMiddle, {
          [styles.disabledSelect]:
            applyDisabledStyles &&
            (!canEdit || !isRecognizedCriterion(criterion?.id || '')),
        })}
      >
        <OperatorSelect
          criterion={criterion || undefined}
          value={operator}
          isDisabled={!canEdit}
          onChange={onChangeOperator}
        />
      </div>

      <div
        className={cx(styles.criteriaSelectorRight, {
          [styles.disabledSelect]:
            applyDisabledStyles &&
            (!canEdit || !isRecognizedCriterion(criterion?.id || '')),
        })}
      >
        <ValueSelect
          hasError={hasError}
          key={criterion?.id || ''}
          criterion={criterion?.id || ''}
          criterionType={criterion?.type || ''}
          operator={operator}
          onChange={onChangeValues}
          value={values}
          isDisabled={!canEdit}
          editableTextToQuery={editableTextToQuery}
          audienceName={audienceName}
          menuPlacement={menuPlacement}
        />
        {hasError && <Box className={styles.requiredError}>Required</Box>}
      </div>

      <div className={styles.criteriaSelectorDelete}>
        {(!isAlone || clearLastItem) && canEdit && (
          <button type="button" onClick={onDelete}>
            <Icon iconName="Delete" iconType="SVG" size={13} />
          </button>
        )}
      </div>
    </div>
  );
};
