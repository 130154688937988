import camelcaseKeys from 'camelcase-keys';
import { logError } from 'hooks/datadog';
import { Step } from 'models/journeys/journey';
import {
  BaseStepError,
  JourneyErrors,
  StepErrors,
} from 'models/journeys/journey-errors';
import { includes } from 'utility/objectUtils';

const serverStartStepErrorTypes = [
  'root_step_id',
  'trigger',
  'trigger_criterion',
] as const;
type ServerStartStepErrorTypes = typeof serverStartStepErrorTypes[number];
type ServerStartStepErrors = BaseStepError<ServerStartStepErrorTypes>;

type ServerEmailChannelErrorTypes = 'address' | 'subject' | 'preview';
type ServerNotificationCenterChannelErrorTypes = 'title';
type ServerPushChannelErrorTypes = 'text';

const serverCommunicationErrorTypes = [
  'title',
  'design_id',
  'design_processing_state',
  'acknowledgement',
] as const;
type ServerCommunicationStepErrorTypes = typeof serverCommunicationErrorTypes[number];
type ServerCommunicationStepErrors = BaseStepError<
  ServerCommunicationStepErrorTypes
> & {
  email_channel?: BaseStepError<ServerEmailChannelErrorTypes>;
  notification_center_channel?: BaseStepError<
    ServerNotificationCenterChannelErrorTypes
  >;
  push_channel?: BaseStepError<ServerPushChannelErrorTypes>;
};

const serverDecisionStepErrorTypes = ['order', 'criterion'] as const;
type ServerDecisionStepErrorTypes = typeof serverDecisionStepErrorTypes[number];
type ServerDecisionStepErrors = BaseStepError<ServerDecisionStepErrorTypes>;

const serverDelayStepErrorTypes = ['unit', 'quantity'] as const;
type ServerDelayStepErrorTypes = typeof serverDelayStepErrorTypes[number];
type ServerDelayStepErrors = BaseStepError<ServerDelayStepErrorTypes>;

type ServerStepErrors =
  | ServerStartStepErrors
  | ServerCommunicationStepErrors
  | ServerDecisionStepErrors
  | ServerDelayStepErrors;

type ServerJourneyGraphErrors =
  | ['initiation', ServerStartStepErrors]
  | [
      'steps',
      Record<Step['id'], Exclude<ServerStepErrors, ServerStartStepErrors>>
    ];

const isServerCommunicationStepError = (
  errors: unknown
): errors is ServerCommunicationStepErrors =>
  !!errors &&
  !Object.keys(errors as ServerCommunicationStepErrors).some(
    (key) =>
      !(
        includes(serverCommunicationErrorTypes, key) ||
        key === 'email_channel' ||
        key === 'notification_center_channel' ||
        key === 'push_channel'
      )
  );

const isServerStartStepError = (
  errors: unknown
): errors is ServerStartStepErrors =>
  !!errors &&
  !Object.keys(errors as ServerStartStepErrors).some(
    (key) =>
      !serverStartStepErrorTypes.includes(key as ServerStartStepErrorTypes)
  );

export const deserializeJourneyErrors = (
  error: Error,
  startStepId = 'initiation'
): JourneyErrors => {
  try {
    const parsedErrors: ServerJourneyGraphErrors[] = JSON.parse(error.message)
      .errors;

    return parsedErrors.reduce((journeyErrors: JourneyErrors, graphErrors) => {
      const [errorKey, stepErrors] = graphErrors;

      if (errorKey === 'initiation') {
        return {
          ...journeyErrors,
          graph: {
            ...journeyErrors.graph,
            [startStepId]: deserializeStepErrors(stepErrors),
          },
        };
      }

      if (errorKey === 'steps') {
        return {
          ...journeyErrors,
          graph: {
            ...journeyErrors.graph,
            ...Object.keys(stepErrors).reduce(
              (errors, stepId) => ({
                ...errors,
                [stepId]: deserializeStepErrors(stepErrors[stepId]),
              }),
              {}
            ),
          },
        };
      }

      return journeyErrors;
    }, {});
  } catch (e) {
    if (e instanceof Error)
      logError(new Error('Unable to parse journey validation errors.'), {
        input: error,
        errorMessage: e.message,
      });
    return {};
  }
};

const deserializeStepErrors = (stepErrors: ServerStepErrors): StepErrors => {
  if (isServerStartStepError(stepErrors)) {
    if (stepErrors.trigger) {
      // eslint-disable-next-line no-param-reassign
      stepErrors.trigger = ['Start Type is required'];

      // Will be fixed if Start Type is fixed
      if (stepErrors.root_step_id) {
        // eslint-disable-next-line no-param-reassign
        delete stepErrors.root_step_id;
      }
    }
    return camelcaseKeys(stepErrors);
  }

  if (isServerCommunicationStepError(stepErrors)) {
    const {
      email_channel: emailChannel,
      notification_center_channel: notificationCenterChannel,
      push_channel: pushChannel,
      ...rest
    } = stepErrors;

    const emailChannelErrors: Record<string, string[] | undefined> = {};
    if (emailChannel) {
      for (const channelKey of Object.keys(emailChannel)) {
        const errorKey = `emailChannel_${channelKey}`;
        const errorValue =
          emailChannel[channelKey as ServerEmailChannelErrorTypes];

        emailChannelErrors[errorKey] = errorValue;
      }
    }

    const notificationCenterChannelErrors: Record<
      string,
      string[] | undefined
    > = {};
    if (notificationCenterChannel) {
      for (const channelKey of Object.keys(notificationCenterChannel)) {
        const errorKey = `notificationCenterChannel_${channelKey}`;
        const errorValue =
          notificationCenterChannel[
            channelKey as ServerNotificationCenterChannelErrorTypes
          ];

        notificationCenterChannelErrors[errorKey] = errorValue;
      }
    }

    const pushChannelErrors: Record<string, string[] | undefined> = {};
    if (pushChannel) {
      for (const channelKey of Object.keys(pushChannel)) {
        const errorKey = `pushChannel_${channelKey}`;
        const errorValue = pushChannel[channelKey as 'text'];

        pushChannelErrors[errorKey] = errorValue;
      }
    }

    return camelcaseKeys({
      ...rest,
      ...emailChannelErrors,
      ...notificationCenterChannelErrors,
      ...pushChannelErrors,
    });
  }

  return camelcaseKeys(stepErrors);
};
