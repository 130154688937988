import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components';
import { FieldLabel } from 'DesignSystem/Typography';
import * as FlexLayout from 'DesignSystem/Layout/Flex';
import {
  CommunicationStep,
  DeliveryChannel,
  DeliveryChannels,
  EmailChannel,
  PushChannel,
  isNotifitcationCenterChannel,
  isEmailChannel,
  isPushChannel,
  CHANNEL_NAMES,
  NotificationCenterChannel,
} from 'models/journeys/journey';
import { isCommunicationStepError } from 'models/journeys/journey-errors';
import { ChannelSelection } from 'components/channel-selection/channel-selection';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { Label } from 'models/label';
import { CheckedChannelsState } from 'components/channel-selection/hooks';
import { useDefaultEmailAddress } from 'hooks/email-alias';
import { ProgramOrAuthor } from 'hooks/useAuthorsList';
import { EmailSenderAlias } from 'models/publisher/settings';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { NotificationCard } from '../../../../Editors/Publisher/Deliver/Notifications/NotificationCard';
import { useJourneyValidationErrors } from '../../JourneyErrors/useJourneyValidationErrors';
import { EmailConfig } from './channel-configs';
import { ContentSettings } from './content-settings/ContentSettings';
import styles from './communication-config.module.css';
import { ChannelDelivery } from './channel-delivery/ChannelDelivery';

export type DeliveryChannelOption = {
  id?: string;
  name: keyof DeliveryChannels;
  label: string;
};

export const ReadOnlyCommunicationConfig: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  const emailChannel = step.channels.find(isEmailChannel);
  const { id: programId } = useProgram();
  const acknowledgementsEnabled = useFeatureFlagsQuery(
    programId,
    'Journey.CommunicationStep.Acknowledgments'
  ).data?.value;
  return (
    <FlexLayout.Flex spread>
      <Box width="50%">
        {emailChannel && (
          <Box>
            <Box>
              <FieldLabel>Email Alias</FieldLabel>
              <Box margin={[8, 0, 0, 0]}>
                {emailChannel.emailSenderAlias?.senderEmail}
              </Box>
            </Box>
            <Box margin={[24, 0, 0, 0]}>
              <FieldLabel>Subject and Preview Text</FieldLabel>
              <NotificationCard
                notification={{
                  text: emailChannel.subject,
                  previewText: emailChannel.previewText,
                  order: 0,
                }}
                disablePush
              />
            </Box>
          </Box>
        )}
        {acknowledgementsEnabled && step.acknowledgement?.customLabel && (
          <div className={styles.configSection}>
            <Box margin={[24, 0, 0, 0]}>
              <FieldLabel>Acknowledgement Label</FieldLabel>
              <Box margin={[8, 0, 0, 0]}>
                {step.acknowledgement?.customLabel}
              </Box>
            </Box>
          </div>
        )}
      </Box>
    </FlexLayout.Flex>
  );
};

export const CommunicationConfig: React.FC<{
  step: CommunicationStep;
  onUpdateStep: (step: CommunicationStep) => void;
}> = ({ step, onUpdateStep }) => {
  const { errorsForStep } = useJourneyValidationErrors();
  const stepErrors = errorsForStep(step.id);
  const errors = isCommunicationStepError(stepErrors) ? stepErrors : undefined;

  const emailChannelConfig = step.channels.find(isEmailChannel);
  const notificationCenterChannelConfig = step.channels.find(
    isNotifitcationCenterChannel
  );
  const pushChannelConfig = step.channels.find(isPushChannel);

  const { data: defaultAddress } = useDefaultEmailAddress();
  const {
    handleAcknowledgementLabelChange,
    handleChannelDeliveryChange,
    handleEmailAliasChange,
    handleNotificationCenterAuthorChange,
    handleNotificationCenterTitleChange,
    handleNotificationCenterMarkAsImportantChange,
    handlePreviewTextChange,
    handlePushMessageChange,
    handleSubjectChange,
    onChannelSelectionChange,
    defaultChannel,
  } = useChannelStepStateModifiers({
    step,
    onUpdateStep,
  });

  const emailChannel = emailChannelConfig ?? defaultChannel.email();

  const notificationCenterIsEnabled = useNotificationCenterEnabled();

  const emailChannelSubject =
    emailChannel.subject === 'Default' || !emailChannel.subject
      ? ''
      : emailChannel.subject;
  const emailChannelPreviewText =
    emailChannel.previewText === 'Default' || !emailChannel.previewText
      ? ''
      : emailChannel.previewText;
  const emailChannelSenderAlias =
    emailChannel.emailSenderAlias || defaultAddress;

  const notificationCenterChannel =
    notificationCenterChannelConfig ?? defaultChannel.notificationCenter();

  const notificationCenterTitle = notificationCenterChannel?.text ?? '';

  const defaultAuthor = useDefaultAuthor(step.designId, step.author);

  const pushChannel: PushChannel = pushChannelConfig ?? defaultChannel.push();

  const acknowledgementLabel = step.acknowledgement
    ? {
        preset: step.acknowledgement?.label,
        text: step.acknowledgement?.customLabel,
      }
    : undefined;

  const { id: programId } = useProgram();
  const acknowledgementsEnabled = useFeatureFlagsQuery(
    programId,
    'Journey.CommunicationStep.Acknowledgments'
  ).data?.value;

  const hasMultipleChannels = step.selectedChannels.length > 1;
  return (
    <FlexLayout.Flex start>
      <Box
        margin={[16, 0, 0, 0]}
        width="720px"
        style={{
          display: 'flex',
          gap: '16px',
          flexDirection: 'column',
        }}
      >
        {(() => {
          if (notificationCenterIsEnabled) {
            return (
              <>
                <ChannelSelection
                  checked={(channel) => step.selectedChannels.includes(channel)}
                  toggleChecked={(channel) => {
                    onChannelSelectionChange(
                      channel,
                      !step.selectedChannels.includes(channel)
                    );
                  }}
                >
                  <ChannelSelection.Email
                    emailAlias={emailChannelSenderAlias}
                    onEmailAliasChange={handleEmailAliasChange}
                    subject={emailChannelSubject}
                    onSubjectChange={handleSubjectChange}
                    previewText={emailChannelPreviewText}
                    onPreviewTextChange={handlePreviewTextChange}
                    errors={
                      errors && {
                        emailAlias: errors.emailChannelAddress || [],
                        subject: errors.emailChannelSubject || [],
                        previewText: errors.emailChannelPreview || [],
                      }
                    }
                  />
                  <ChannelSelection.NotificationCenter
                    author={step.author}
                    initialAuthor={defaultAuthor}
                    onAuthorChange={handleNotificationCenterAuthorChange}
                    title={notificationCenterTitle}
                    onTitleChange={handleNotificationCenterTitleChange}
                    markAsImportant={notificationCenterChannel.markAsImportant}
                    onMarkAsImportantChange={
                      handleNotificationCenterMarkAsImportantChange
                    }
                    errors={
                      errors && {
                        title: errors.notificationCenterChannelTitle || [],
                      }
                    }
                  >
                    <ChannelSelection.PushNotification
                      pushMessage={pushChannel.text}
                      onPushMessageChange={handlePushMessageChange}
                      errors={
                        errors && {
                          pushMessage: errors.pushChannelText || [],
                        }
                      }
                    />
                  </ChannelSelection.NotificationCenter>
                </ChannelSelection>

                <ChannelDelivery
                  onSelectionChange={handleChannelDeliveryChange}
                  value={step.channelDelivery}
                  configurable={hasMultipleChannels}
                />
              </>
            );
          }

          return (
            <EmailConfig
              errors={errors}
              disabled={false}
              onEmailAliasChange={handleEmailAliasChange}
              emailSenderAlias={emailChannelSenderAlias}
              subject={emailChannelSubject}
              onSubjectChange={handleSubjectChange}
              previewText={emailChannelPreviewText}
              onPreviewTextChange={handlePreviewTextChange}
            />
          );
        })()}

        {acknowledgementsEnabled && (
          <div className={styles.configSection}>
            <ContentSettings
              acknowledgementLabel={acknowledgementLabel}
              onAcknowledgementLabelChange={handleAcknowledgementLabelChange}
              errors={errors}
            />
          </div>
        )}
      </Box>
    </FlexLayout.Flex>
  );
};

function useDefaultAuthor(
  designId: number | undefined,
  author: ProgramOrAuthor | undefined
) {
  const [prevDesignId, setPrevDesignId] = useState<number | undefined>(
    designId
  );
  const [defaultAuthor, setDefaultAuthor] = useState<
    ProgramOrAuthor | undefined
  >(undefined);

  if (prevDesignId !== designId) {
    setPrevDesignId(designId);
  }

  // Track if the designId has changed
  const hasDesignIdChanged =
    prevDesignId !== undefined && prevDesignId !== designId;

  if (author && !defaultAuthor) {
    // If this is the first author, set it as the default
    setDefaultAuthor(author);
  } else if (hasDesignIdChanged) {
    // Otherwise, if we already have a default author but
    // we've loaded new content, reset the default author
    setDefaultAuthor(undefined);
    setPrevDesignId(designId);
  }

  return defaultAuthor;
}

function useChannelStepStateModifiers({
  onUpdateStep,
  step,
}: {
  step: CommunicationStep;
  onUpdateStep: (step: CommunicationStep) => void;
}) {
  const { resolveErrors } = useJourneyValidationErrors();

  function onChannelSelectionChange(
    channel: keyof CheckedChannelsState,
    checked: boolean
  ) {
    const channelExists = step.channels.some((c) => c.name === channel);

    const updatedToggledChannels = toggleChannelSelection(channel, checked);
    const hasMultipleChannels = updatedToggledChannels.length > 1;
    const updatedStep: CommunicationStep = {
      ...step,
      selectedChannels: updatedToggledChannels,
      channelDelivery: hasMultipleChannels
        ? step.channelDelivery ?? 'optimize'
        : undefined,
    };

    if (checked && !channelExists) {
      updatedStep.channels = [...step.channels, defaultChannel(channel)];
    } else if (channelExists) {
      updatedStep.channels = step.channels.map((c) =>
        c.name === channel ? { ...c, checked } : c
      );
    }

    onUpdateStep(updatedStep);
  }

  function toggleChannelSelection(
    channel: keyof CheckedChannelsState,
    checked: boolean
  ) {
    let newSelectedChannels = checked
      ? [...step.selectedChannels, channel]
      : step.selectedChannels.filter((c) => c !== channel);

    // if notification center is unchecked, remove the push channel as well
    if (!checked && channel === 'notification_center') {
      newSelectedChannels = newSelectedChannels.filter((c) => c !== 'push');
    }
    return newSelectedChannels;
  }

  function upsertChannel(channel: DeliveryChannel) {
    const channelExists = step.channels.some((c) => c.name === channel.name);
    const selectedChannels = channelExists
      ? step.selectedChannels
      : [...step.selectedChannels, channel.name];
    if (channelExists) {
      onUpdateStep({
        ...step,
        channels: step.channels.map((c) =>
          c.name === channel.name ? channel : c
        ),
        selectedChannels,
      });
    } else {
      onUpdateStep({
        ...step,
        channels: [...step.channels, channel],
        selectedChannels,
      });
    }
  }

  function emailChannel(): EmailChannel {
    return {
      name: CHANNEL_NAMES.EMAIL,
      previewText: '',
      subject: '',
    };
  }

  function notificationCenterChannel(): NotificationCenterChannel {
    return {
      name: CHANNEL_NAMES.NOTIFICATION_CENTER,
      text: '',
      markAsImportant: true,
    };
  }

  function pushChannel(): PushChannel {
    return {
      name: CHANNEL_NAMES.PUSH,
      text: '',
    };
  }

  function defaultChannel(channel: keyof CheckedChannelsState) {
    switch (channel) {
      case 'email':
        return emailChannel();
      case 'notification_center':
        return notificationCenterChannel();
      case 'push':
        return pushChannel();
      default:
        throw new Error(`Unknown channel: ${channel}`);
    }
  }

  defaultChannel.email = emailChannel;
  defaultChannel.notificationCenter = notificationCenterChannel;
  defaultChannel.push = pushChannel;

  const handleEmailAliasChange = (emailSenderAlias: EmailSenderAlias) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((channel) =>
      channel.name === CHANNEL_NAMES.EMAIL
        ? {
            ...channel,
            emailSenderAlias,
            programContactAddressId: parseInt(emailSenderAlias.id, 10),
          }
        : channel
    );

    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelAddress']);
  };

  const handleSubjectChange = (subject: string) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((channel) =>
      channel.name === CHANNEL_NAMES.EMAIL ? { ...channel, subject } : channel
    );
    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelSubject']);
  };

  const handlePreviewTextChange = (previewText: string) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((channel) =>
      channel.name === CHANNEL_NAMES.EMAIL
        ? { ...channel, previewText }
        : channel
    );
    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelPreview']);
  };

  const handleNotificationCenterTitleChange = (title: string) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((channel) =>
      channel.name === CHANNEL_NAMES.NOTIFICATION_CENTER
        ? { ...channel, text: title }
        : channel
    );
    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['notificationCenterChannelTitle']);
  };

  const handleNotificationCenterAuthorChange = (author: ProgramOrAuthor) => {
    onUpdateStep({
      ...step,
      author,
    });
  };

  const handleNotificationCenterMarkAsImportantChange = (
    markAsImportant: boolean
  ) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((channel) =>
      channel.name === CHANNEL_NAMES.NOTIFICATION_CENTER
        ? { ...channel, markAsImportant }
        : channel
    );
    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
  };

  const handlePushMessageChange = (pushMessage: string) => {
    upsertChannel({
      name: CHANNEL_NAMES.PUSH,
      text: pushMessage,
    });
    resolveErrors(step.id, ['pushChannelText']);
  };

  const handleAcknowledgementLabelChange = (label: Label | undefined) => {
    const acknowledgement = label && {
      label: label.preset,
      customLabel: label.text,
    };
    const existingAcknowledgement = step.acknowledgement && {
      label: step.acknowledgement.label,
      customLabel: step.acknowledgement.customLabel,
    };

    if (
      JSON.stringify(acknowledgement) !==
      JSON.stringify(existingAcknowledgement)
    ) {
      onUpdateStep({
        ...step,
        acknowledgement,
      });
      resolveErrors(step.id, ['acknowledgement']);
    }
  };

  function handleChannelDeliveryChange(
    value: CommunicationStep['channelDelivery']
  ) {
    onUpdateStep({
      ...step,
      channelDelivery: value,
    });
  }

  return {
    defaultChannel,
    handleAcknowledgementLabelChange,
    handleChannelDeliveryChange,
    handleEmailAliasChange,
    handleNotificationCenterAuthorChange,
    handleNotificationCenterMarkAsImportantChange,
    handleNotificationCenterTitleChange,
    handlePreviewTextChange,
    handlePushMessageChange,
    handleSubjectChange,
    onChannelSelectionChange,
  };
}
